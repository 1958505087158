export class Pod {
    name: string;
    namespace: string;
    phase: string;
    terminating: boolean;
    initContainersInfo: ContainersInfo[];
    containersInfo: ContainersInfo [];
    state: string;
    podIP: string;
    nodeName: string;
    grafanaUrl: string;
    whitelistSourceRange: string[];
    runningTime: number;
    assignedPods: Pod[];
}

export class ContainersInfo {
    containerName: string;
    image: string;
    containerID: string;
    state: string;
    ready: boolean;
    message: string;
    reason: string;
    restartCount: number;
}
