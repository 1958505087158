import { Component, OnChanges, OnInit, SimpleChanges } from '@angular/core';
import { ActivatedRoute, Params, Router } from '@angular/router';
import { LoginInfoHelper } from 'src/app/helpers/login-info-helper';
import { Training } from 'src/app/models/training.model';
import { TrainingService } from 'src/app/services/training.service';
import { InfoDialogComponent } from '../dialogs/info-dialog/info-dialog.component';
import { PopupHelper } from 'src/app/helpers/pop-up-helper';
import { MatSnackBar } from '@angular/material/snack-bar';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { InfoPopUpComponent } from '../pop-ups/info-pop-up/info-pop-up.component';
import { SubmitDialogComponent } from '../dialogs/submit-dialog/submit-dialog.component';
import { CourseService } from 'src/app/services/course.service';
import { TranslateService } from '@ngx-translate/core';
import { WorkspaceService } from 'src/app/services/workspace.service';


@Component({
  selector: 'kwconnect-training-view',
  templateUrl: './kwconnect-training-view.component.html',
  styleUrls: ['./kwconnect-training-view.component.scss']
})
export class KWConnectTrainingViewComponent implements OnInit {
  public trainingCreating = false;
  public userHasTraining = false;
  public userMail: string;
  public trainingInfo: Training;
  public courseIdNew: string;
  public showTrainingInfo = false;
  public stoppingTraining = false;
  public creatingTraining = false;
  public showCourseInfo = false;
  public registrationCode;
  public courseExists;
  public subscriptionValid;
  public subscriptionEnd: Date;
  public subscriptionStart: Date;


  constructor(public loginLoginInfoHelper: LoginInfoHelper,
              private trainingService: TrainingService,
              private courseService: CourseService,
              private dialog: MatDialog,
              private popUp: MatSnackBar,
              private popUpHelper: PopupHelper,
              private router: Router,
              private activatedRoute: ActivatedRoute,
              private translateService: TranslateService,
              private workspaceService: WorkspaceService
            ) {
    this.loadCourseIdFromParam();
    this.prepareKWConnect();
    this.checkTraining();
    this.getRegistrationCode();
    this.userMail = loginLoginInfoHelper.getLoginUserFromStorage().mail;
    var language = navigator.languages ? navigator.languages[0] : navigator.language;
    if (language.includes("en") || language.includes("de")) {
      this.translateService.use(language);
    } else {
      this.translateService.use("en");
    }
  }
  
  ngOnInit(): void {
  }

  private checkTraining() {
    this.trainingService.getTrainingsByLoginUser().subscribe(
      (data) => {
        if (data.length == 0) {
          this.userHasTraining = false;
        } else  {
          // this.courseIdNew = data[0].course.courseName;
          this.userHasTraining = true;
          this.trainingInfo = data[0];
        }
      },
      (error) => {
        this.trainingCreating = false;
        this.translateService.get('TRAINING.ERROR_FETCHING_TRAINING').subscribe((res) => {
          const message: string = res;
          this.showErrorMessage(message);
        })
      }
    )
  }

  stopTraining() { 
    const separator = "|";
    const courseName = this.trainingInfo.course.courseName 
    this.translateService.get('TRAINING.DELETE_TRAINING_DIALOG',{value: courseName}).subscribe((res) => {
      const title_message = new String(res).split(separator);
      const dialogRef = this.openSubmitDialog(
        title_message[0],
        title_message[1]);
  
      dialogRef.afterClosed().subscribe(result => {
        if (result) {
          this.translateService.get('TRAINING.IS_AVAILABLE.DELETING_TRAINING').subscribe((deleting) => {
            const deletingString = new String(deleting);
            this.stoppingTraining = true;
            this.popUpHelper.showLoadingPopUp(deletingString + "!");
            this.translateService.get('TRAINING.DELETE_TRAINING').subscribe((mes) => {
            const success_failure = new String(mes).split(separator);
            this.trainingService.deleteTraining(this.trainingInfo).subscribe(
              (data) => {
                this.popUpHelper.showPopUp(success_failure[0] + "!");
                this.userHasTraining = false;
                this.stoppingTraining = false;
                this.trainingInfo = null;
                this.showCourseInfo = false;
                this.loadCourseIdFromParam();
              },
              (error) => {
                this.popUp.dismiss();
                this.trainingCreating = false;
                this.stoppingTraining = false;
                this.showErrorMessage(success_failure[1] + ": " +  error.error);
                this.loadCourseIdFromParam();
              }
            );
          });
        });
      }
    });
  })
}

  
  private openSubmitDialog(title: string, message: string): MatDialogRef<SubmitDialogComponent, any> {
    return this.dialog.open(SubmitDialogComponent, {
        width: '450px',
        data: { title, message }
    });
  }


  private loadCourseIdFromParam() {
    // if (!this.userHasTraining) {
      this.activatedRoute.queryParamMap
          .subscribe((params) => {
            this.courseIdNew = params.get("courseId");
            this.checkIfCourseExists();
          }
        );
      // }
  }

  public createTraining() {
    this.trainingCreating = true;
    this.showTrainingInfo = true;
    this.translateService.get('TRAINING.CREATING_TRAINING').subscribe((res) => {
      const message = new String(res);
      this.popUpHelper.showLoadingPopUp(message + "!");

      this.activatedRoute.queryParamMap
      .subscribe((params) => {
          this.trainingService.createKWConnectTraining(params.get("courseId")).subscribe(
            (data) => {
              this.trainingCreating = false;
              this.trainingInfo = data
              this.userHasTraining = true;  
              this.translateService.get('TRAINING.CREATED_TRAINING').subscribe((res) => {
                const message = new String(res);
                this.popUpHelper.showPopUp(message + "!");
              });
              // this.popUpHelper.showPopUp("Notification has been sent to email: " + this.userMail);
            },
            (error) => {
              this.trainingCreating = false;
              this.translateService.get('TRAINING.CREATING_TRAINING_FAILED').subscribe((res) => {
                const message = new String(res);
                this.showErrorMessage(message + ": " +  error.error);
                this.popUp.dismiss()
              });
            }
          )
        }
      );    
    });
  }

  toggleShowCourseInfo() {
    this.showCourseInfo = !this.showCourseInfo;
  }

  prepareKWConnect() {
    this.getKWConnectAttributes();

  }

  openWorkspaceWebpage() {
    window.open("https://clients.amazonworkspaces.com/", "_blank");
  }

  showErrorMessage(message: string) {
    this.dialog.open(InfoDialogComponent, {
      width: '450px',
      data: { "title" : "Error", "message": message},
    });
  }
  
  showSnackBar(translationValue: string) {
    this.translateService.get(translationValue).subscribe((res) => {
      const message = new String(res);
      this.popUp.openFromComponent(InfoPopUpComponent, {
        data: { message },
        duration: 5000
      });
    });

  }


  checkIfCourseExists() {
    this.courseService.getCourse(this.courseIdNew).subscribe(
      (data) => {
        this.courseExists = true;
        // left empty on purpose
      }, (error) => {
        this.courseExists = false;
        this.translateService.get('TRAINING.COURSE_NOT_AVAILABLE').subscribe((res) => {
          const message = new String(res);
          this.showErrorMessage(message + ": " + error.error);
        })
      }
    )
  }

  changeLanguage(locale: string) {
    if (!locale) {
      return;
    }
    this.translateService.use(locale);
  }

  getTooltip() {
    this.translateService.get('TRAINING.IS_AVAILABLE.COPY_TO_CLIPBOARD').subscribe((res) => {
      return new String(res);
    });
  }

  getKWConnectAttributes() {
    const subscriptionValidKey = "kwtrain_subscription_valid";
    const subscriptionEndKey = "kwtrain_subscription_end";
    const subscriptionStartKey = "kwtrain_subscription_start";
      this.trainingService.getKWConnectKeycloakAttributes().subscribe(
        (data) => {
          this.subscriptionValid = data[subscriptionValidKey];
          this.subscriptionStart = data[subscriptionStartKey];
          this.subscriptionEnd = data[subscriptionEndKey];
        }, (error) => {
          this.translateService.get('TRAINING.KEYCLOAK.ATTRIBUTES.ERROR').subscribe((res) => {
            const message = new String(res);
            this.showErrorMessage(message + ": " + error.error);
          });
        }
      )
  }

  getRegistrationCode() {
      this.workspaceService.getDirectoryInfo().subscribe(
        (data) => {
            this.registrationCode = data.registrationCode;
        }, 
        (error) => {
          this.showSnackBar('TRAINING.DIRECTORY_INFO.ERROR');
        }
      )
  }

  checkSubsription() {
    return this.subscriptionValid !== null && this.subscriptionValid === 'true';
  }
}