import { Injectable } from '@angular/core';
import { environment } from '../../environments/environment';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable, of } from 'rxjs';
import { catchError, mergeMap } from 'rxjs/operators';
import { InfoDialogComponent } from '../components/dialogs/info-dialog/info-dialog.component';
import { MatDialog } from '@angular/material/dialog';
import { User } from '../models/user.model';

@Injectable({
  providedIn: 'root'
})
export class ConfigInitService {

  private CONFIG_API = 'config';

  constructor(
      private httpClient: HttpClient,
      private dialog: MatDialog,
    ) {

  }


  // public getKeycloakConfig(): Observable<string[]> {
  //     return this.httpClient.get<string[]>(environment.dmePortalApi + this.CONFIG_API + '/keycloak');
  // }

  public isAwsWorkspaceEnabled(): Observable<boolean> {
    return this.httpClient.get<boolean>(environment.dmePortalApi + this.CONFIG_API + '/isAwsWorkspaceEnabled');
  }

  public defaultBackupLocation(): Observable<string> {
    return this.httpClient.get<string>(environment.dmePortalApi + this.CONFIG_API + '/defaultBackupLocation');
  }

  public isAws(): Observable<boolean> {
    return this.httpClient.get<boolean>(environment.dmePortalApi + this.CONFIG_API + '/isAws');
  }
  public getAdminPortalInfo(): Observable<string> {
    return this.httpClient.get<string>(environment.dmePortalApi + this.CONFIG_API + '/adminPortalInfo');
  }

  public getKeycloakConfig(): Observable<any>{
    return this.httpClient.get(environment.dmePortalApi + this.CONFIG_API + '/keycloak', { observe: 'response' })
      .pipe(
          catchError((error) => {
              this.dialog.open(InfoDialogComponent, {
                width: '450px',
                data: { "title" :"Error", "message": error.error},
              });
              return of(null)
          }),
          mergeMap((response) => {
              if (response && response.body) {
                  return of(response.body);
              } else {
                  return of(null);
              }
          })
      );
  }


}