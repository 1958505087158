import { Component, OnInit, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA, MatDialog } from '@angular/material/dialog';
import { AppServer } from 'src/app/models/app-server.model';
import { BackupRestoreService } from 'src/app/services/backup-restore.service';
import { map, catchError, startWith, count } from 'rxjs/operators';
import { HttpEventType, HttpErrorResponse } from '@angular/common/http';
import { Observable, of } from 'rxjs';
import { UntypedFormGroup, UntypedFormControl } from '@angular/forms';
import { PopupHelper } from 'src/app/helpers/pop-up-helper';
import { ConfirmActionDialogComponent } from '../confirm-action-dialog/confirm-action-dialog.component';
import { LoginInfoHelper } from 'src/app/helpers/login-info-helper';
import { RestoreErrorDialogComponent } from '../restore-error-dialog/restore-error-dialog.component';
import { InfoDialogComponent } from '../info-dialog/info-dialog.component';

export enum RestoreState {
    DONE,
    IN_PROGRESS,
    ERROR
}
export interface RestoreStateInfo {
    state: RestoreState;
    message: any;
}

export interface RestoreDialogData {
    selectedServers: AppServer[];
    availableServers: AppServer[];
}

@Component({
    selector: 'app-restore-dialog',
    templateUrl: './restore-dialog.component.html',
    styleUrls: ['./restore-dialog.component.scss']
})

export class RestoreDialogComponent implements OnInit {

    public backupFiles: string[];
    public displayedColumns: string[] = ['name', 'namespace', 'state'];
    public uploading = false;
    public processing = false;
    public uploadedFileName = null;
    public uploadProgress = 0;
    public restoreStates: RestoreStateInfo[] = [];
    public selectedBackFileName = null;

    public backupPreparing = false;
    public backupPreparingError: string = null;
    selectedBackup = null;
    restoreState = RestoreState;

    public selectedBackupServer: AppServer;
    public searchBackupServer: UntypedFormControl;
    public restoreServerIniFormControl: UntypedFormControl;
    public restoreJBossCliFormControl: UntypedFormControl;
    public restoreVCSFormControl: UntypedFormControl;
    public restorePostgresFormControl: UntypedFormControl;
    public restoreMTextInitFormControl: UntypedFormControl;
    public backupForm: UntypedFormGroup;

    filteredServers: Observable<AppServer[]>;

    showAdvanced = false;
    confirmText = "";
    generatedHash: string = "";
    constructor(
        public dialogRef: MatDialogRef<RestoreDialogComponent>,
        public backupRestoreService: BackupRestoreService,
        private popupHelper: PopupHelper,
        @Inject(MAT_DIALOG_DATA) public data: RestoreDialogData,
        private dialog: MatDialog,
        private loginInfoHelper: LoginInfoHelper
    ) { }

    ngOnInit() {
        this.data.availableServers.sort((a, b) => a.serverName.localeCompare(b.serverName));
        this.data.selectedServers.sort((a, b) => a.serverName.localeCompare(b.serverName));
        
        this.backupRestoreService.getDefaultBackupsNames(this.data.selectedServers[0]).subscribe(data => {
            this.backupFiles = data.sort();
        });
        this.searchBackupServer = new UntypedFormControl();
        // this.selectedBackupServer = new UntypedFormControl();
        this.restoreServerIniFormControl = new UntypedFormControl(true);
        this.restoreJBossCliFormControl = new UntypedFormControl(true);
        this.restoreVCSFormControl = new UntypedFormControl(true);
        this.restorePostgresFormControl = new UntypedFormControl(true);
        this.restoreMTextInitFormControl = new UntypedFormControl(true);
        // this.selectedBackupServer = this.data.availableServers[0];
        this.backupForm = new UntypedFormGroup({
            // selectedBackupServer: this.selectedBackupServer,
            searchBackupServer: this.searchBackupServer,
            restoreServerIni: this.restoreServerIniFormControl,
            restoreJBossCli: this.restoreJBossCliFormControl,
            restoreVCS: this.restoreVCSFormControl,
            restorePostgres: this.restorePostgresFormControl,
            restoreMTextInit: this.restoreMTextInitFormControl
         });
        this.filteredServers = this.searchBackupServer.valueChanges.pipe(
            startWith(''),
            map(value => this._filter(value)),
        );
    }

    uploadFile($event) {
        this.uploading = true;
        this.uploadedFileName = null;
        let serverNames = "";
        this.generatedHash = this.generateRestoreFolderName();
        this.data.selectedServers.forEach((server) => serverNames = serverNames + server.serverName + "_" + this.generatedHash + "," );
        serverNames = serverNames.substring(0, serverNames.length - 1);
        this.backupRestoreService
            .uploadBackupFile($event.target.files[0], serverNames)
            .pipe(
                map(event => {
                    switch (event.type) {
                        case HttpEventType.UploadProgress:
                            this.uploadProgress = Math.round(
                                (event.loaded * 100) / event.total
                            );
                            break;
                        case HttpEventType.Response:
                            return event;
                    }
                }),
                catchError((error: HttpErrorResponse) => {
                    return of(`upload failed.`);
                })
            )
            .subscribe((event: any) => {
                if (this.uploadProgress === 100) {
                    this.uploading = false;
                    this.processing = true;
                }
                if (typeof event === 'object') {
                    this.uploading = false;
                    this.processing = false;
                    this.uploadedFileName = $event.target.files[0].name;
                }
            });
    }

    submitRestore(selectedBackup: string) {
        if (this.loginInfoHelper.privilegeAdmin()) {
            const dialog = this.openSubmitDialog();
            dialog.afterClosed().subscribe(data => {
                if(data) {
                    this.restoreSelectedServers(selectedBackup);
                }
            })
        } else {
            this.restoreSelectedServers(selectedBackup);
        }
    }

    private restoreSelectedServers(selectedBackup: string) {
        let serverNames = "";

        if (selectedBackup === 'chooseBackupServer') {
            this.generatedHash = this.generateRestoreFolderName();
            this.data.selectedServers.forEach((server) => serverNames = serverNames + server.serverName + "_" + this.generatedHash + "," );

            this.backupPreparing = true;
            this.backupPreparingError = null;
            this.selectedBackupServer = this.data.availableServers.filter((server) => server.serverName === this.searchBackupServer.value)[0]  // only left
            
            this.backupRestoreService.prepareBackupFromServer(serverNames, this.selectedBackupServer).subscribe(
                done => {
                    this.backupPreparing = false;
                    this.backupPreparingError = 'NONE';
                    this.restoreServers();
                },
                error => {
                    this.backupPreparing = false;
                    this.backupPreparingError = error.message;
                    
                    this.showErrorMessage("There was an error while restoring from default backup: \n" + error.error );
                }
            );
        } else {
            const defaultBackupLocation = localStorage.getItem('defaultBackupLocation');
            if (selectedBackup === "uploadedFile") {
                this.data.selectedServers.forEach((server) => serverNames = serverNames + server.serverName + "_" + this.generatedHash + "," );

                this.restoreServers();
            } else if (defaultBackupLocation && defaultBackupLocation === 'aws') {
                this.generatedHash = this.generateRestoreFolderName();
                this.data.selectedServers.forEach((server) => serverNames = serverNames + server.serverName + "_" + this.generatedHash + "," );

                this.backupPreparing = true;

                const majorVersion = this.data.selectedServers[0].majorVersion;
                const firstServerName = this.data.selectedServers[0].serverName;
                this.backupRestoreService.prepareDefaultBackupFromS3(
                    this.generatedHash, selectedBackup, firstServerName, serverNames, majorVersion)
                    .subscribe(
                    (data) => {
                        this.backupPreparing = false;
                        this.backupPreparingError = 'NONE';
                        this.restoreServers();          
                    },
                    (error) => {
                        this.backupPreparing = false;
                        this.showErrorMessage("There was an error while restoring from default backup: \n" + error.error );
                    }
                );
            } else {
                this.generatedHash = this.generateRestoreFolderName();
                this.data.selectedServers.forEach((server) => serverNames = serverNames + server.serverName + "_" + this.generatedHash + "," );
                this.restoreServers();
            }
        }
    }

    restoreServers() {
        let restoreServesDone = 0;
        const restoreServerIni = this.restoreServerIniFormControl.value;
        const restoreJBossCli = this.restoreJBossCliFormControl.value;
        const restoreVCS = this.restoreVCSFormControl.value;
        const restorePostgres = this.restorePostgresFormControl.value;
        const restoreMTextInit = this.restoreMTextInitFormControl.value;        
        
        this.data.selectedServers.forEach((appServer) => {
            this.restoreStates[appServer.serverName] = { state: RestoreState.IN_PROGRESS, message: '' };
            this.backupRestoreService.restorePod(this.generatedHash, appServer, this.selectedBackFileName, restoreServerIni, restoreJBossCli, restoreVCS,
                restorePostgres, restoreMTextInit)
              .subscribe(
                done => {
                    this.restoreStates[appServer.serverName] = { state: RestoreState.DONE, message: '' };
                    restoreServesDone++;
                    if (this.data.selectedServers.length === restoreServesDone) {
                        this.popupHelper.showPopUp('Restore (' + restoreServesDone + ' servers) done');
                        this.dialogRef.close();
                    }
                },
                error => {
                    const title = "Restore Error";
                    const message = error.error;

                    this.dialog.open(RestoreErrorDialogComponent, {
                        width: '450px',
                        data: { title, message, appServer }
                    });

                    this.restoreStates[appServer.serverName] = { state: RestoreState.ERROR, message: error };
                }
            );
        });
    }

    selectBackFileName(selectedBackup: string) {
        if (selectedBackup === 'uploadedFile' || selectedBackup === 'chooseBackupServer') {
            this.selectedBackFileName = null;
        } else {
            this.selectedBackFileName = selectedBackup;
        }
    }

    private openSubmitDialog(): MatDialogRef<ConfirmActionDialogComponent, any> {
        const actionName = "restore";
        let appServers = this.data.selectedServers;
        return this.dialog.open(ConfirmActionDialogComponent, {
            width: '450px',
            data: { actionName, appServers}
        });
    }

    onCloseClick(): void {
        this.dialogRef.close();
    }

    private _filter(value: string): AppServer[] {
        const filterValue = value.toLowerCase();
        return this.data.availableServers.filter(option => option.serverName.toLowerCase().includes(filterValue));
    }

    existsInArray() {
        const searchedBackupServer =  this.data.availableServers.filter(server => server.serverName.toLowerCase() === this.searchBackupServer.value);
        return searchedBackupServer.length === 1;
    }
    
    showErrorMessage(message: string) {
        this.dialog.open(InfoDialogComponent, {
        width: '450px',
        data: { "title" : "Error", "message": message},
        });
    }


    generateRestoreFolderName(): string {
        let result = '';
        const characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
        const charactersLength = characters.length;
        let length = 8;
        for (let i = 0 ; i < length; i++ ) {
            result += characters.charAt(Math.floor(Math.random() * charactersLength));
        }
        return result;
    }
      
}


