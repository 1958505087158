import { BrowserModule } from '@angular/platform-browser';
import { NgModule, APP_INITIALIZER } from '@angular/core';
import { MatToolbarModule,} from '@angular/material/toolbar';
import { MatTableModule, }  from '@angular/material/table';
import { MatButtonModule, }  from '@angular/material/button';
import { MatProgressSpinnerModule, }  from '@angular/material/progress-spinner';
import { MatFormFieldModule, }  from '@angular/material/form-field';
import { MatInputModule, }  from '@angular/material/input';
import { MatSelectModule, }  from '@angular/material/select';
import { MatDatepickerModule, }  from '@angular/material/datepicker';
import { MatNativeDateModule, }  from '@angular/material/core';
import { MatIconModule, }  from '@angular/material/icon';
import { MatTooltipModule, }  from '@angular/material/tooltip';
import { MatDividerModule, }  from '@angular/material/divider';
import { MatDialogModule, }  from '@angular/material/dialog';
import { MatCheckboxModule, }  from '@angular/material/checkbox';
import { MatProgressBarModule, }  from '@angular/material/progress-bar';
import { MatButtonToggleModule, }  from '@angular/material/button-toggle';
import { MatAutocompleteModule, }  from '@angular/material/autocomplete';
import { MatSnackBarModule, }  from '@angular/material/snack-bar';
import { MatBadgeModule, }  from '@angular/material/badge';
import { MAT_DATE_LOCALE, }  from '@angular/material/core';
import { MAT_SNACK_BAR_DEFAULT_OPTIONS, }  from '@angular/material/snack-bar';
import { MatSortModule, }  from '@angular/material/sort';
import { MatSidenavModule, }  from '@angular/material/sidenav';
import { MatExpansionModule, }  from '@angular/material/expansion';
import { MatChipsModule, }  from '@angular/material/chips';
import { MatListModule, }  from '@angular/material/list';
import { MatPaginatorModule, }  from '@angular/material/paginator';
import { MatTabsModule, }  from '@angular/material/tabs';
import { MatRadioModule}  from '@angular/material/radio';
import {MatGridListModule} from '@angular/material/grid-list';
import { ClipboardModule } from '@angular/cdk/clipboard';
import { MatMenuModule } from '@angular/material/menu';


import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { TopNavComponent } from './components/top-nav/top-nav.component';
import { AppServersViewComponent } from './components/app-servers-view/app-servers-view.component';
import { HttpClientModule, HTTP_INTERCEPTORS, HttpClient } from '@angular/common/http';
import { AppServersCreationComponent } from './components/app-servers-creation/app-servers-creation.component';
import { ReactiveFormsModule, FormsModule } from '@angular/forms';
import { InfoDialogComponent } from './components/dialogs/info-dialog/info-dialog.component';
import { environment } from 'src/environments/environment';
import { RestoreDialogComponent } from './components/dialogs/restore-dialog/restore-dialog.component';
import { LocationStrategy, HashLocationStrategy, CommonModule } from '@angular/common';
import { SubmitDialogComponent } from './components/dialogs/submit-dialog/submit-dialog.component';
import { InfoPopUpComponent } from './components/pop-ups/info-pop-up/info-pop-up.component';
import { SelectVersionDialogComponent } from './components/dialogs/select-version-dialog/select-version-dialog.component';
import { SideNavComponent } from './components/side-nav/side-nav.component';
import { PortalUserViewComponent } from './components/portal-user-view/portal-user-view.component';
import { LoginCredencialInterceptor } from './helpers/login-credencial.interceptor';
import { EditAppServerDialogComponent } from './components/dialogs/edit-app-server-dialog/edit-app-server-dialog.component';
import { EventsLogViewComponent } from './components/events-log-view/events-log-view.component';
import { InvoicesViewComponent } from './components/invoices-view/invoices-view.component';
import { InvoiceConfirmDialogComponent } from './components/dialogs/invoice-confirm-dialog/invoice-confirm-dialog.component';
import { InvoiceHistoryComponent } from './components/invoices-view/invoice-history/invoice-history.component';
import { InvoiceConfirmViewComponent } from './components/invoices-view/invoice-confirm-view/invoice-confirm-view.component';
import { InvoiceModifyDialogComponent } from './components/dialogs/invoice-modify-dialog/invoice-modify-dialog.component';
import { BackupSelectionDialogComponent } from './components/dialogs/backup-selection-dialog/backup-selection-dialog.component';
import { ServerBackupListComponent } from './components/dialogs/server-backup-list/server-backup-list.component';
import { AppServerDetailComponent } from './components/app-servers-view/app-server-detail/app-server-detail.component';
// tslint:disable-next-line: max-line-length
import { AnotherMtextVersionDialogComponent } from './components/dialogs/another-mtext-version-dialog/another-mtext-version-dialog.component';
import { MtextBuildsStateDialogComponent } from './components/dialogs/mtext-builds-state-dialog/mtext-builds-state-dialog.component';
import { VolumesViewComponent } from './components/volumes-view/volumes-view.component';
import { ConfirmActionDialogComponent } from './components/dialogs/confirm-action-dialog/confirm-action-dialog.component';
import { KeycloakAngularModule, KeycloakService } from 'keycloak-angular';
import { RestoreErrorDialogComponent } from './components/dialogs/restore-error-dialog/restore-error-dialog.component';
import { AnnouncementPopUpComponent } from './components/pop-ups/announcement-pop-up/announcement-pop-up.component';
import { AnnoucementDialogComponent } from './components/dialogs/annoucement-dialog/annoucement-dialog.component';
import { AnnouncementCreationComponent } from './components/announcement-creation/announcement-creation.component';
import { AnnouncementViewComponent } from './components/announcement-view/announcement-view.component';
import { ConfigInitService } from './services/config-init.service';
import { switchMap } from 'rxjs/operators';
import { fromPromise } from "rxjs/internal/observable/fromPromise";
import { TrainingComponent } from './components/training/training.component';
import { CourseComponent } from './components/course/course.component';
import { WorkspaceUserComponent } from './components/workspace-user/workspace-user.component';
import { KWConnectTrainingViewComponent } from './components/kwconnect-training-view/kwconnect-training-view.component';
import { InfoViewComponent } from './components/info-view/info-view.component';
import { DocumentationComponent } from './components/info-view/documentation/documentation.component';
import { ChangeLogComponent } from './components/info-view/change-log/change-log.component';
import { ContactsComponent } from './components/info-view/contacts/contacts.component';
import { MatStepperModule } from '@angular/material/stepper';
import { TrainingDeleteDialogComponent } from './components/dialogs/training-delete-dialog/training-delete-dialog.component';
import { UserSelectionDialogComponent } from './components/dialogs/user-selection-dialog/user-selection-dialog.component';
import { WorkspaceInfoDialogComponent } from './components/dialogs/workspace-info-dialog/workspace-info-dialog.component';
import { ErrorComponent } from './components/error/error.component';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import { TranslateLoader, TranslateModule } from '@ngx-translate/core';

// import { fromPromise } from 'rxjs/observable/fromPromise';
export function createTranslateLoader(http: HttpClient) {
    return new TranslateHttpLoader(http, './assets/i18n/', '.json');
}

export const protectedResourceMap: [string, string[]][] = [
    [environment.dmePortalApi, ['user.read']]
];

function initializeKeycloak(
  keycloak: KeycloakService,
  configInit: ConfigInitService
  ) {
    configInit.isAwsWorkspaceEnabled().subscribe(
        (data) => {
            localStorage.setItem("isAwsWorkspaceEnabled", data + "");
        }
    );
    configInit.isAws().subscribe(
        (data) => {
            localStorage.setItem("isAws", data + "");
        }
    );
    configInit.defaultBackupLocation().subscribe(
        (data) => {
            localStorage.setItem("defaultBackupLocation", data + "");
        }
    );
    return () => configInit.getKeycloakConfig().pipe(switchMap<any, any>((config) => {
        return fromPromise(keycloak.init({
            config: {
                url: config["keycloakUrl"],
                realm: config["keycloakRealm"],
                clientId: config["keycloakClientId"]
            },
            initOptions: {
                onLoad: 'login-required'
            },
            bearerExcludedUrls: []
        }))
    })).toPromise()

     
}

@NgModule({
    declarations: [
        AppComponent,
        AppServersViewComponent,
        TopNavComponent,
        AppServersCreationComponent,
        InfoDialogComponent,
        RestoreDialogComponent,
        SubmitDialogComponent,
        InfoPopUpComponent,
        SelectVersionDialogComponent,
        SideNavComponent,
        PortalUserViewComponent,
        EditAppServerDialogComponent,
        EventsLogViewComponent,
        InvoicesViewComponent,
        InvoiceConfirmDialogComponent,
        InvoiceHistoryComponent,
        InvoiceConfirmViewComponent,
        InvoiceModifyDialogComponent,
        BackupSelectionDialogComponent,
        ServerBackupListComponent,
        AppServerDetailComponent,
        AnotherMtextVersionDialogComponent,
        MtextBuildsStateDialogComponent,
        VolumesViewComponent,
        ConfirmActionDialogComponent,
        RestoreErrorDialogComponent,
        AnnouncementPopUpComponent,
        AnnoucementDialogComponent,
        AnnouncementCreationComponent,
        AnnouncementViewComponent,
        WorkspaceUserComponent,
        KWConnectTrainingViewComponent,
        CourseComponent,
        TrainingComponent,
        InfoViewComponent,
        DocumentationComponent,
        ChangeLogComponent,
        ContactsComponent,
        TrainingDeleteDialogComponent,
        UserSelectionDialogComponent,
        WorkspaceInfoDialogComponent,
        ErrorComponent,
    ],
    imports: [
        BrowserModule,
        AppRoutingModule,
        BrowserAnimationsModule,
        HttpClientModule,
        MatToolbarModule,
        MatTableModule,
        MatButtonModule,
        MatProgressSpinnerModule,
        MatFormFieldModule,
        MatInputModule,
        MatSelectModule,
        MatDatepickerModule,
        MatNativeDateModule,
        MatIconModule,
        MatTooltipModule,
        MatDividerModule,
        MatDialogModule,
        MatCheckboxModule,
        MatProgressBarModule,
        MatAutocompleteModule,
        MatSnackBarModule,
        MatSortModule,
        MatBadgeModule,
        MatButtonToggleModule,
        MatSidenavModule,
        MatExpansionModule,
        MatListModule,
        MatPaginatorModule,
        FormsModule,
        MatChipsModule,
        ReactiveFormsModule,
        MatRadioModule,
        MatTabsModule,
        MatGridListModule,
        KeycloakAngularModule,
        ClipboardModule,
        CommonModule,
        MatTabsModule,
        MatIconModule,
        MatButtonModule,
        MatStepperModule,
        MatSidenavModule,
        MatListModule,
        MatExpansionModule,
        MatTableModule,
        MatMenuModule,
        TranslateModule.forRoot({
            loader: {
                provide: TranslateLoader,
                useFactory: (createTranslateLoader),
                deps: [HttpClient]
            }
        })
    ],
    exports: [
        BrowserModule,
        AppRoutingModule,
        BrowserAnimationsModule,
        HttpClientModule,
        MatToolbarModule,
        MatTableModule,
        MatButtonModule,
        MatProgressSpinnerModule,
        MatFormFieldModule,
        MatInputModule,
        MatSelectModule,
        MatDatepickerModule,
        MatNativeDateModule,
        MatIconModule,
        MatTooltipModule,
        MatDividerModule,
        MatDialogModule,
        MatCheckboxModule,
        MatProgressBarModule,
        MatSnackBarModule,
        MatSortModule,
        MatBadgeModule,
        MatButtonToggleModule,
        MatSidenavModule,
        MatExpansionModule,
        MatListModule,
        MatPaginatorModule,
        FormsModule,
        MatChipsModule,
        ReactiveFormsModule,
        MatRadioModule,
        MatTabsModule,
        MatGridListModule,
        MatAutocompleteModule,
        KeycloakAngularModule,
    ],
    providers: [
        { provide: MAT_DATE_LOCALE, useValue: 'en-GB' },
        { provide: LocationStrategy, useClass: HashLocationStrategy },
        { provide: MAT_SNACK_BAR_DEFAULT_OPTIONS, useValue: { duration: 7000, horizontalPosition: 'right' } },
        { provide: HTTP_INTERCEPTORS, useClass: LoginCredencialInterceptor, multi: true },
        { provide: APP_INITIALIZER, useFactory: initializeKeycloak, multi: true, deps: [KeycloakService, ConfigInitService] }
    ],
    bootstrap: [AppComponent]
})
export class AppModule { }


