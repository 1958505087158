import { Component, Inject, Input, OnInit } from '@angular/core';
import { CookieService } from 'ngx-cookie-service';
import { Annoucement } from 'src/app/models/annoucement.model';
import { AppComponent, getHash } from '../../app.component';
import {MatGridListModule} from '@angular/material/grid-list';
import { AnnoucementService } from 'src/app/services/announcement.service';
import { MatDialog } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { PopupHelper } from 'src/app/helpers/pop-up-helper';
import { InfoDialogComponent } from '../dialogs/info-dialog/info-dialog.component';
import { MatTableDataSource } from '@angular/material/table';
import { LoginInfoHelper } from 'src/app/helpers/login-info-helper';

@Component({
  selector: 'app-announcement-view',
  templateUrl: './announcement-view.component.html',
  styleUrls: ['./announcement-view.component.scss']
})
export class AnnouncementViewComponent implements OnInit {
  colorMapping = {
    SEVERE : "severe",
    WARNING : "warn",
    INFO : "info",
    CONFIG : "config"
  }
  @Input() public data: Array<Annoucement>;
  @Input() public allAnnouncements: Array<Annoucement>;
  @Input() public dialogOpened: boolean;
  
  datasourceAnnouncement = new MatTableDataSource<Annoucement>();


  constructor(
    private dialog: MatDialog,
    private popUp: MatSnackBar,
    private popUpHelper: PopupHelper,
    private cookieService: CookieService,
    private annoucementService: AnnoucementService,
    private loginInfoHelper: LoginInfoHelper,
  ) { 

    if (!this.dialogOpened) {
      this.annoucementService.getPlannedAnnoucements().subscribe(
        (data) => {
          this.datasourceAnnouncement.data = data;  
        },
        (error) => {
          this.popUpHelper.showPopUp("There was an error fetching announcements!");    
        }
      )
    }    
  }

  ngOnInit(): void {
  }

    setReadAnnouncement(item: any) : void {
      this.cookieService.set(AppComponent.cookieAnnouncementPrefix + item.dateTo + "_" + getHash(item), "true", 365);
      this.data = this.data.filter(function( obj ) {
        return obj.id !== item.id;
    });
  }

  deleteAnnouncement(annoucement: Annoucement) : void {
    this.popUpHelper.showLoadingPopUp("Announcement is being deleted!");
    this.annoucementService.deleteAnnoucement(annoucement).subscribe(
      (data) => {
        let tempAnnouncements = this.datasourceAnnouncement.data;

        tempAnnouncements = tempAnnouncements.filter(
          (tempAnnouncement) => 
            (tempAnnouncement.id !== annoucement.id)
        );    
        this.datasourceAnnouncement.data = tempAnnouncements;
        this.popUpHelper.showPopUp("Announcement was successfully deleted!");    
      },
      (error) => {
        this.showErrorMessage("There was an error deleting Training\n" + error.error);
        this.popUp.dismiss();
      }
    )
  }



  showErrorMessage(message: string) {
    this.dialog.open(InfoDialogComponent, {
      width: '450px',
      data: { "title" : "Error", "message": message},
    });
  }
}
