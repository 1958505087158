import { Component, OnInit } from '@angular/core';
import { UntypedFormGroup, UntypedFormControl, Validators} from '@angular/forms';
import { MatChipInputEvent } from '@angular/material/chips';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { MatTableDataSource } from '@angular/material/table';
import { ImageRegistryService } from 'src/app/services/image-registry.service';
import { AppServerService, ServerLimitLabels } from 'src/app/services/app-server.service';
import { InfoDialogComponent } from '../dialogs/info-dialog/info-dialog.component';
import { Router } from '@angular/router';
import { AppServer } from 'src/app/models/app-server.model';
import { RegistryImageVersion } from 'src/app/models/registry-image-version.model';
import { NamespaceService } from 'src/app/services/namespace.service';
import { Observable } from 'rxjs';
import { startWith, map } from 'rxjs/operators';
import { PopupHelper } from 'src/app/helpers/pop-up-helper';
import { CusotmerService } from 'src/app/services/cusotmer.service';
import { Customer } from 'src/app/models/customer.model';
import { SubmitDialogComponent } from '../dialogs/submit-dialog/submit-dialog.component';
import { LoginInfoHelper } from 'src/app/helpers/login-info-helper';
import {COMMA, ENTER, SPACE} from '@angular/cdk/keycodes';
import { Pod } from 'src/app/models/pod.model';
import { environment } from 'src/environments/environment';
import { AnotherMtextVersionDialogComponent } from '../dialogs/another-mtext-version-dialog/another-mtext-version-dialog.component';
import { MtextBuildsStateDialogComponent } from '../dialogs/mtext-builds-state-dialog/mtext-builds-state-dialog.component';
import { HttpClient} from "@angular/common/http";
import { Injectable } from '@angular/core';



//ADDED END

@Component({
    selector: 'app-pods-creation',
    templateUrl: './app-servers-creation.component.html',
    styleUrls: ['./app-servers-creation.component.scss']
})
export class AppServersCreationComponent implements OnInit {
    public minorVersions: string[];
    public majorVersions: string[];
    public domainPostfixList: string[];
    public customers: Customer[];
    public majorVersionsWiithBuilds: RegistryImageVersion[];
    public partners: string[];
    public currencies: string[];

    public namespaceList;
    serversCreating = false;
    public createServerForm: UntypedFormGroup;
    public customerForm: UntypedFormGroup;
    public podInfoForm: UntypedFormGroup;
    private namespaceFormControl: UntypedFormControl;
    public minServerStartDate = new Date();
    minorVersionDefault: string;
    public displayedColumns: string[] = ['serverName', 'domainName', 'mtextVersion', 'namespace', 'serverStartDate',
     'serverEndDate', 'customerName','actions'];
    private serversToCreate: AppServer[] = [];
    public dataSource = new MatTableDataSource(this.serversToCreate);
    isValidating = false;
    filteredNamespaces: Observable<string[]>;
    filteredCustomers: Observable<string[]>;
    private isInternServer = true;

    isTestMtextVersionForm: UntypedFormControl;
    advancedSettingsExpanded = false;
    public advancedForm: UntypedFormGroup;
    selectedAccess = 'intern';
    publicAddress = '0.0.0.0/0';
    public minorTestVersions: string[];
    public majorTestVersions: string[];
    isMinorVersionLoading = false;

    maxRunningUserServers: number;
    maxCreatedUserServers: number;
    actualRunningUserServers: number;
    actualCreatedUserServers: number;
    maxRunningAllServers: number;
    actualRunningAllServers: number;

    readonly separatorKeysCodes: number[] = [ENTER, COMMA, SPACE];
    whitelistSourceRange: string[] = [];
    internWhitelistSourceRange: string[] = [];

    constructor(
        private appServerService: AppServerService,
        private namespaceService: NamespaceService,
        private customerService: CusotmerService,
        private registryService: ImageRegistryService,
        private dialog: MatDialog,
        private router: Router,
        private popUpHelper: PopupHelper,
        public loginInfoHelper: LoginInfoHelper,
        private http: HttpClient,
        ) {

    }

    ngOnInit() {
        this.getMtextVersions();
        this.getDomainPostfixList();
        this.getCustomersNamespaces();
        this.getServerLimitsInfo();
        this.getCustomerList();
        this.loadWhitelistSourceRange();
        this.loadPartners();


        this.currencies = ['EUR', 'CZK'];
        this.namespaceFormControl =  new UntypedFormControl('', [Validators.required,
            Validators.pattern('[a-z0-9]*'), Validators.maxLength(15)]);

        this.filteredNamespaces = this.namespaceFormControl.valueChanges.pipe(
            startWith(''),
            map(value => this.filterNamespaces(value))
        );
        const endServerDate = new Date();
        endServerDate.setHours(0, 0, 0, 0);
        endServerDate.setDate(endServerDate.getDate() + 7);

        this.createCustomerFormGroup();
        this.isTestMtextVersionForm = new UntypedFormControl(false),
        this.advancedForm = new UntypedFormGroup({
            isTestMtextVersionForm: this.isTestMtextVersionForm,
            majorVersion: new UntypedFormControl({value: '', disabled: true}, [Validators.required]),
            minorVersion: new UntypedFormControl({value: '', disabled: true}),
            cvsName: new UntypedFormControl('git'),
        });

        this.createServerForm = new UntypedFormGroup({
            serverName: new UntypedFormControl('', [Validators.required, Validators.maxLength(20), Validators.pattern('[a-z0-9-\-]*')]),
            majorVersion: new UntypedFormControl({value: 'Loading', disabled: true}, [Validators.required]),
            minorVersion: new UntypedFormControl({value: 'Loading', disabled: true}),
            domainPostfix: new UntypedFormControl({value: 'Loading', disabled: true}),
            notes: new UntypedFormControl(),
            serverStartDate: new UntypedFormControl(new Date()),
            serverEndDate: new UntypedFormControl(endServerDate),
            namespace: this.namespaceFormControl,
            price: new UntypedFormControl('',
                [Validators.pattern('[0-9]+(\.[0-9][0-9]?)?'), Validators.min(0), Validators.max(1000000)]),
            currency: new UntypedFormControl(this.currencies[0]),
            partner: new UntypedFormControl(''),
            contactPersonName: new UntypedFormControl(),
            contactMail: new UntypedFormControl(),
            contactPhone: new UntypedFormControl(),
            paymentStartDate: new UntypedFormControl(new Date()),
            paymentEndDate: new UntypedFormControl(null),
            serverCustomer: this.customerForm,
            appAdvancedSetting: this.advancedForm,
        });
        this.customerSettingDisable(true);
    }



    private createCustomerFormGroup() {
        const customerName =  new UntypedFormControl('', [Validators.maxLength(20)]);
        const debitor = new UntypedFormControl('');
        this.filteredCustomers = customerName.valueChanges.pipe(
            startWith(''),
            map(value => this.filterCustomers(value).map(customer => customer.customerName)),
        );

        customerName.valueChanges.subscribe(selectedCustomerName => {
            if (this.customers) {

                const selectedCustomer = this.customers.find(customer => customer.customerName === selectedCustomerName);
                if (selectedCustomer !== undefined) {
                    debitor.setValue(selectedCustomer.debitor);
                }
            }
        });
        this.customerForm = new UntypedFormGroup({
            customerName,
            debitor
        });
    }

    private filterNamespaces(value: string): string[] {
        const filterValue = value.toLowerCase();
        return this.namespaceList.filter(namespace => namespace.toLowerCase().includes(filterValue));
    }

    private filterCustomers(value: string): Customer[] {
        const filterValue = value.toLowerCase();
        return this.customers.filter(customer => customer.customerName.toLowerCase().includes(filterValue));
    }

    private getCustomersNamespaces() {
        this.namespaceService.getCustomerNamespaces().subscribe(
            namespaces => this.namespaceList = namespaces
        );
    }



    private getMtextVersions() {
        this.registryService.getRegistryImageVersions().subscribe(data => {
            this.majorVersions = data.map(mtextVersion => mtextVersion.majorVersion);
            this.majorVersions.sort((a, b) => (a > b ? -1 : 1));
            this.majorVersionsWiithBuilds = data;
            this.createServerForm.get('majorVersion').setValue(this.majorVersions[0]);
            this.createServerForm.get('majorVersion').enable();
            this.onVersionChange(this.majorVersions[0]);
            if (!this.isAws()) {
                this.loadMtextTestVersions();
            }
        });
    }

    private getDomainPostfixList() {
        this.appServerService.getDomainPostfixList().subscribe( data => {
            this.domainPostfixList = data;
            this.createServerForm.get('domainPostfix').setValue(this.domainPostfixList[0]);
            this.createServerForm.get('domainPostfix').enable();
        });
    }

    private getCustomerList() {
        this.customerService.getCustomers().subscribe(customers => {
            this.customers = customers;
        });
    }

    private getServerLimitsInfo() {
        this.appServerService.getServerCountLimits().subscribe( data => {
            this.maxRunningUserServers = data[ServerLimitLabels.MAX_RUNNING_USER_SERVERS];
            this.maxCreatedUserServers = data[ServerLimitLabels.MAX_CREATED_USER_SERVERS];
            this.actualRunningUserServers = data[ServerLimitLabels.ACTUAL_RUNNING_USER_SERVERS];
            this.actualCreatedUserServers = data[ServerLimitLabels.ACTUAL_CREATED_USER_SERVERS];
            this.maxRunningAllServers = data[ServerLimitLabels.MAX_RUNNING_ALL_SERVERS];
            this.actualRunningAllServers = data[ServerLimitLabels.ACTUAL_RUNNING_ALL_SERVERS];
        });
    }

    customerSettingDisable(disable: boolean) {
        const namespaceControl = this.createServerForm.get('namespace');
        const customerNameControl = this.customerForm.get('customerName');
        this.isInternServer = disable;
        if (disable) {
            namespaceControl.disable();
            namespaceControl.setValue('');
            customerNameControl.disable();
            customerNameControl.setValue('');
        } else {
            namespaceControl.enable();
            customerNameControl.enable();
        }
    }

    onVersionChange(selectedVersion) {
        this.minorVersions = this.majorVersionsWiithBuilds.filter(version =>
            version.majorVersion === selectedVersion)[0].minorVersions.map(build => build.name);
        this.minorVersions.sort((a, b) => b.localeCompare(a, undefined, {numeric: true, sensitivity: 'base'}));
        this.createServerForm.get('minorVersion').setValue(this.minorVersions[0]);
        this.createServerForm.get('minorVersion').enable();
    }

    public addServerToCreate(serverToCreate: AppServer) {
        if (this.serverLimitExceeded()) {
            this.openDialog('Server limit exceeded', 'The limit for running/created servers has been exceeded');
            return;
        }
        this.isValidating = true;
        if (!this.createServerForm.invalid) {
            this.appServerService.isExistsAppServer(serverToCreate.serverName).subscribe(isExists => {
                this.isValidating = false;
                if (!isExists) {
                    if (this.isServerInTable(serverToCreate.serverName)) {
                        this.openDialog('Server name error',
                            'Server with name "' + serverToCreate.serverName + '" is already prepare to run in table.');
                    } else {
                        serverToCreate.podInfo = new Pod();
                        serverToCreate.podInfo.whitelistSourceRange = this.whitelistSourceRange;
                        this.addAppServer(serverToCreate);
                    }
                } else {
                    this.openDialog('Server name error',
                        'Server with name "' + serverToCreate.serverName + '" is already running.');
                }
            });
        } else {
            this.isValidating = false;
        }
    }


    /**
     * Create Server and AWS Workspace after Submit Button is clicked
     */
    public submitServersToCreate() {
        this.serversCreating = true;
        this.appServerService.createAppServer(this.serversToCreate).subscribe(
        data => {
            this.serversCreating = false;
            this.router.navigateByUrl('/list');
        },
        error => {
            if (error.status === 406) {
                this.openDialog('Server limit exceeded', 'The limit for running/created servers has been exceeded.'
                    + 'Contact an administrator(info/contacts) to increase the maximum number of servers.');
            }
            this.serversCreating = false;
            this.popUpHelper.showErrorPopUp('Create servers failed');

        });



    }



    private addAppServer(serverToCreate: AppServer) {
        serverToCreate.serverCustomer = this.isInternServer ? null : serverToCreate.serverCustomer;
        serverToCreate.testVersion = this.isTestMtextVersionForm.value;
        if (serverToCreate.testVersion) {
            serverToCreate.majorVersion = this.advancedForm.get('majorVersion').value;
            serverToCreate.minorVersion = this.advancedForm.get('minorVersion').value;
        }
        this.serversToCreate.push(serverToCreate);
        this.dataSource = new MatTableDataSource(this.serversToCreate);
    }



    public isAws() {
        return localStorage.getItem("isAws") === "true";    
    }

    private isServerInTable(serverName: string): boolean {
        return this.serversToCreate.some(server => server.serverName === serverName);
    }

    private openDialog(title: string, message: string): void {
        this.dialog.open(InfoDialogComponent, {
            width: '450px',
            data: { title, message }
        });
    }

    public deleteServerToCreate(index: number) {
        this.serversToCreate.splice(index, 1);
        this.dataSource = new MatTableDataSource(this.serversToCreate);
    }

    public errorHandling = (control: string, error: string) => {
        return this.createServerForm.controls[control].hasError(error);
    }

    private serverLimitExceeded(): boolean {
        const serverCount = this.serversToCreate.length;
        return  !(serverCount + this.actualRunningUserServers < this.maxRunningUserServers
                && serverCount + this.actualCreatedUserServers < this.maxCreatedUserServers
                && serverCount + this.actualRunningAllServers < this.maxRunningAllServers);
    }

    private loadPartners() {
        this.appServerService.getPartners().subscribe(data => this.partners = data);
    }

    /************************************************** ADVANCED SETTING **********************************************/
    public loadMtextTestVersions() {
        this.majorTestVersions = this.majorVersions.filter(majorVersion => majorVersion !== '6.10');
        this.advancedForm.get('majorVersion').setValue(this.majorTestVersions[0]);
        this.loadRegistryImageVersions(this.majorTestVersions[0]);
    }

    loadRegistryImageVersions(majorVersion) {
        this.isMinorVersionLoading = true;
        this.registryService.getImagesFromDockerRegistry(majorVersion).subscribe(
            data => {
                this.minorTestVersions = data;
                this.advancedForm.get('minorVersion').setValue(this.minorTestVersions[0]);
                this.isMinorVersionLoading = false;
            },
            err => {
                this.popUpHelper.showErrorPopUp('Builds state loading failed');
                this.isMinorVersionLoading = false;
            }
        );
    }

    public refreshMinorTestVersions() {
        this.loadRegistryImageVersions(this.advancedForm.get('majorVersion').value);
    }

    public onTestVersionChange() {
        this.loadRegistryImageVersions(this.advancedForm.get('majorVersion').value);
    }

    loadWhitelistSourceRange() {
        this.appServerService.getDefaultWhitelistSourceRange().subscribe(data => {
            this.internWhitelistSourceRange = data;
            if (environment.aws) {
                this.selectedAccess = 'intern';
                this.whitelistSourceRange = this.internWhitelistSourceRange;
            } else {
                this.selectedAccess = 'public';
                this.whitelistSourceRange = [this.publicAddress];
            }

        } );
    }

    changeAccess(event) {
        if (event.value === 'intern') {
            this.whitelistSourceRange = this.internWhitelistSourceRange;
        }
        if (event.value === 'public') {
            this.whitelistSourceRange = [this.publicAddress];
        }
        if (event.value === 'specify') {
            this.whitelistSourceRange = [];
        }
    }



    addWhitelistSourceRangeValue(event: MatChipInputEvent): void {
        const input = event.chipInput.inputElement;
        const value = event.value;

        if ((value || '').trim()) {
          this.whitelistSourceRange.push(value);
        }

        if (input) {
          input.value = '';
        }
      }

    removeWhitelistSourceRangeValue(value: string): void {
        const index = this.whitelistSourceRange.indexOf(value);

        if (index >= 0) {
          this.whitelistSourceRange.splice(index, 1);
        }
    }

    isStandaloneVersion() {
        return !this.isTestMtextVersionForm.value;
    }

    showBuildDialog() {
        const dialogRef = this.dialog.open(AnotherMtextVersionDialogComponent, {
            width: '650px',
            data: this.majorTestVersions
        });

        dialogRef.afterClosed().subscribe(
            data => {
                if (data) {
                    this.dialog.open(InfoDialogComponent, {
                        width: '450px',
                        data: {title: 'Build has started', message: 'Build can take several minutes. The status of the build can be '
                        + 'checked by clicking on the button "Show build states. Refresh version list after the build is complete."'}
                    });
                }
            }
        );
    }

    getMaxDate() {
        let now = new Date();
        now.setFullYear(now.getFullYear() + 1);
        return now;
    }


    showBuildHistoryDialog() {
        this.dialog.open(MtextBuildsStateDialogComponent, {
            width: '650px',
            data: this.majorTestVersions
        });
    }


}
