import { Component, OnInit } from '@angular/core';
import { Sort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { PortalUser } from 'src/app/models/portal-user.model';
import { PortaluserService, UserPrivilegeTypes } from 'src/app/services/portal-user.service';
import { PopupHelper } from 'src/app/helpers/pop-up-helper';
import { Observable } from 'rxjs/internal/Observable';
import { UntypedFormControl, UntypedFormGroup } from '@angular/forms';
import { startWith } from 'rxjs/internal/operators/startWith';
import { map } from 'rxjs/internal/operators/map';
import { Router } from '@angular/router';
import { LoginInfoHelper } from 'src/app/helpers/login-info-helper';

@Component({
  selector: 'app-portal-user-view',
  templateUrl: './portal-user-view.component.html',
  styleUrls: ['./portal-user-view.component.scss']
})
export class PortalUserViewComponent implements OnInit {
    tableDataSource: MatTableDataSource<PortalUser>;
    loadedUsers: PortalUser[];
    onlineUsers: PortalUser[];
    public displayedColumns: string[] = ['displayName', 'mail', 'lastActivity', 'createdServers', 'runningServers', 'privileges'];
    hoverUser: PortalUser | null;
    loadingFailed = false;

    filteredUserList: Observable<PortalUser[]>;
    userFilterForm: UntypedFormGroup;
    constructor(
        private portalUserService: PortaluserService,
        private popUpHelper: PopupHelper,
        private loginInfoHelper: LoginInfoHelper,
        private router: Router,
    ) { }

    ngOnInit() {
        if (!this.loginInfoHelper.privilegeAdmin()) {
            this.router.navigate(['/list']);
        } 
        this.tableDataSource = new MatTableDataSource();
        this.loadUsers();

        const userNameFormControl = new UntypedFormControl();
        this.filteredUserList = userNameFormControl.valueChanges.pipe(
            startWith(''),
            map(value => this.filterUsers(value, this.tableDataSource.data)),
        );
        userNameFormControl.valueChanges.subscribe({
            next: value => this.tableDataSource.data = this.filterUsers(value, this.loadedUsers)
        });
        this.userFilterForm = new UntypedFormGroup({
            userNameFormControl,
        });

    }

    sortData(sort: Sort) {
        this.tableDataSource.data = this.tableDataSource.data.sort((a, b) => {
            const isAsc = sort.direction === 'asc';
            switch (sort.active) {
                case 'displayName':
                    return this.compare(a.displayName, b.displayName, isAsc);
                case 'lastActivity':
                    if(!a.lastActivity) {
                        return 1;
                    }
                    if(!b.lastActivity) {
                        return -1;
                    }
                    return this.compare(a.lastActivity.toString(), b.lastActivity.toString(), isAsc);
                default:
                    return 0;
            }
        });
    }

    private compare(a: number | string, b: number | string, isAsc: boolean) {
        return (a < b ? -1 : 1) * (isAsc ? 1 : -1);
    }

    private filterUsers(value: string, list: PortalUser[]): PortalUser[] {
        const filterValue = value.toLowerCase();
        return list.filter(user => user.displayName.toLowerCase().includes(filterValue));
    }

    private loadUsers() {
        this.loadingFailed = false;
        const anHourAgo = Date.now() - 1000 * 60 * 60;
        this.portalUserService.getUsers().subscribe(

            data => {
                data.sort((a, b) => (a.displayName > b.displayName ? 1 : -1));
                this.loadedUsers = data;
                this.tableDataSource.data = data;

                this.onlineUsers = data.filter(user => user.lastActivity && (user.lastActivity as any) > anHourAgo);
            },
            error => {
                this.popUpHelper.showErrorPopUp('User loading failed', error);
                this.loadingFailed = true;
            }
        );
    }

    public hiddenActions() {
        const actionsDiv = document.getElementById('actionsDiv');
        if (actionsDiv !== null) {
            actionsDiv.style.display = 'none';
            this.hoverUser = null;
        }
    }
    public showActions(event: Event, hoverUser: PortalUser) {
        this.hoverUser = hoverUser;
        const actionsDiv = document.getElementById('actionsDiv');
        if (actionsDiv !== null) {
            let tableRow = (event.target as HTMLElement).parentElement;
            while (!(tableRow instanceof HTMLTableRowElement)) {
                tableRow = tableRow.parentElement;
            }
            const sideNavContainer = document.getElementById('sideNavContent') as HTMLDivElement;
            actionsDiv.style.top = tableRow.getBoundingClientRect().top + sideNavContainer.scrollTop - 60 + 'px';
            actionsDiv.style.height = tableRow.getBoundingClientRect().height + 'px';
            actionsDiv.style.display = 'flex';
        }
    }

    public updatePortalUser(portalUser: PortalUser) {
        this.portalUserService.updateUser(portalUser).subscribe(
            data => {
                this.popUpHelper.showPopUp('User info updated');
                this.loadUsers();
            },
            error => {
                this.popUpHelper.showErrorPopUp('User info update failed', error);
            }
        );
    }

    changePrivilege(checked: boolean, portalUser: PortalUser, privilege: UserPrivilegeTypes) {
        if (checked) {
            if (!this.isPrivilegeSet(portalUser, privilege)) {
                portalUser.privileges.push(privilege);
            }
        } else {
            const index = portalUser.privileges.indexOf(privilege, 0);
            if (index > -1) {
                portalUser.privileges.splice(index, 1);
            }
        }
    }

    privilegeTypes(): typeof UserPrivilegeTypes {
        return UserPrivilegeTypes;
    }

    isPrivilegeSet(portalUser: PortalUser, privilege: UserPrivilegeTypes) {
        return portalUser.privileges.includes(privilege);
    }
}
