import { Component, OnInit, OnDestroy, Input} from '@angular/core';
import { AppServerService, ServerLimitLabels } from '../../services/app-server.service';
import { Pod, ContainersInfo } from 'src/app/models/pod.model';
import { AppServer } from 'src/app/models/app-server.model';
import { trigger, state, style, transition, animate } from '@angular/animations';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { Sort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { InfoDialogComponent } from '../dialogs/info-dialog/info-dialog.component';
import { RestoreDialogComponent } from '../dialogs/restore-dialog/restore-dialog.component';
import { BackupRestoreService } from 'src/app/services/backup-restore.service';
import { SubmitDialogComponent } from '../dialogs/submit-dialog/submit-dialog.component';
import { SelectVersionDialogComponent } from '../dialogs/select-version-dialog/select-version-dialog.component';
import { UntypedFormControl, UntypedFormGroup } from '@angular/forms';
import { Observable } from 'rxjs/internal/Observable';
import { PopupHelper } from 'src/app/helpers/pop-up-helper';
import { LoginInfoHelper } from 'src/app/helpers/login-info-helper';
import { EditAppServerDialogComponent } from '../dialogs/edit-app-server-dialog/edit-app-server-dialog.component';
import { Subscription, timer } from 'rxjs';
import { startWith, map } from 'rxjs/operators';
import { BackupSelectionDialogComponent } from '../dialogs/backup-selection-dialog/backup-selection-dialog.component';
import { HttpClient } from '@angular/common/http';
import { ServerBackupListComponent } from '../dialogs/server-backup-list/server-backup-list.component';
import { ContainerStateService, ContainerStateEnum } from 'src/app/services/container-state.service';
import { ConfirmActionDialogComponent } from '../dialogs/confirm-action-dialog/confirm-action-dialog.component';
import { ActivatedRoute, Router } from '@angular/router';
import { TrainingDeleteDialogComponent } from '../dialogs/training-delete-dialog/training-delete-dialog.component';
import { PortalUser } from 'src/app/models/portal-user.model';
import { UserSelectionDialogComponent, UserSelectionDialogData } from '../dialogs/user-selection-dialog/user-selection-dialog.component';
import { TrainingService } from 'src/app/services/training.service';
import { Training } from 'src/app/models/training.model';

enum MainFilterOption {
    ShowAll,
    RunningServers,
    LoadingServers,
    StoppedServers,
    ExpiredServers,
    ExpiredPayments,
    PaidServers,
    Failed,
    MissingInDB,
}
enum AnotherFilterOption {  OnlyMyServers = 'Only my servers',
                            NotMissingInDB = 'Without missing servers in DB',
                            Owner = 'Owner',
                            Namespace = 'Namespace',
                            ServerName = 'ServerName'
                         }
@Component({
    selector: 'app-pods',
    templateUrl: './app-servers-view.component.html',
    styleUrls: ['./app-servers-view.component.scss'],
    animations: [
        trigger('detailExpand', [
            state('collapsed', style({ height: '0px', minHeight: '0' })),
            state('expanded', style({ height: '*' })),
            transition('expanded <=> collapsed', animate('225ms cubic-bezier(0.4, 0.0, 0.2, 1)')),
        ]),
    ]
})

export class AppServersViewComponent implements OnInit, OnDestroy {
    POD_FAILED = 'Failed';
    POD_RUNNING = 'Running';
    POD_LOADING = 'Loading';
    private loadedAppServers: AppServer[] = [];
    tableDataSource: MatTableDataSource<AppServer>;
    public expandedElement: AppServer | null;

    public displayedColumns: string[] = ['actions', 'stateIndicator', 'name', 'mtextVersion',
        'paymentEndDate', 'serverEndDate', 'namespace', 'notes'];
    hoverAppServer: AppServer | null;
    selected: AppServer [] = [];
    public sort: Sort = null;
    appServersRefreshing = false;

    expandedServerLogLastModified: Date;
    // showMoreDetails = false;

    notifyDaysBefore: number;


    filterServersOptions = new Map<MainFilterOption, any>();
    anotherFilterOptions = new Map<AnotherFilterOption, string>();
    selectedMainFilterOption: MainFilterOption = MainFilterOption.ShowAll;
    anotherFiltersExpanded = false;
    anotherFilterForm: UntypedFormGroup = null;
    namespaceList: string[];
    ownerNameList: string[];
    serverNameList: string[];
    filteredNamespaceList: Observable<string[]>;
    filteredOwnerList: Observable<string[]>;
    filteredServerList: Observable<string[]>;

    private countDown: Subscription;
    refreshTimeCounter = 10;
    refreshTimeFormControl: UntypedFormControl;

    maxRunningUserServers: number;
    maxCreatedUserServers: number;
    actualRunningUserServers: number;
    actualCreatedUserServers: number;
    maxRunningAllServers: number;
    maxCreatedAllServers: number;
    actualRunningAllServers: number;
    actualCreatedAllServers: number;
                
    serverNameFilter: string;

    constructor(
        private appServerService: AppServerService,
        public loginInfoHelper: LoginInfoHelper,
        private dialog: MatDialog,
        private popUpHelper: PopupHelper,
        private containerStateService: ContainerStateService,
        private trainingService: TrainingService,
        private route: ActivatedRoute,
        private router: Router,
    ) { }

    ngOnInit() {
        this.tableDataSource = new MatTableDataSource();
        this.refreshTimeFormControl = new UntypedFormControl();
        this.addMainFilterOptions();
        this.loadAppServers();
        this.getNotifyDaysBefore();

        const saveRefreshTime = localStorage.getItem('refresh_server_view_time');
        if (saveRefreshTime === null) {
            this.refreshTimeCounter = 10;
            localStorage.setItem('refresh_server_view_time', '' + this.refreshTimeCounter);
            this.refreshTimeFormControl.setValue(this.refreshTimeCounter);
        } else {
            this.refreshTimeCounter = +saveRefreshTime;
            this.refreshTimeFormControl.setValue(saveRefreshTime);
        }
        this.countDown = timer(0, 1000).subscribe(() => this.countdownRefresh());

    }

    ngOnDestroy() {
        const filterForm = this.anotherFilterForm.get('serverFormControl');
        if (filterForm) {
            filterForm.setValue('');
        }
        this.countDown.unsubscribe();
        this.countDown = null;
    }

    countdownRefresh() {
        if (this.refreshTimeCounter !== -1) {
            this.refreshTimeFormControl.setValue(this.refreshTimeCounter);
            --this.refreshTimeCounter;
            if (this.refreshTimeCounter === 0) {
                if (!document.hidden) {
                    this.loadAppServers();
                }
                this.refreshTimeCounter = +localStorage.getItem('refresh_server_view_time');
            }
        }
    }

    changeRefreshTime(value: string) {
        localStorage.setItem('refresh_server_view_time', value);
        this.refreshTimeCounter = +value;

    }

    public reloadAppServers() {
        this.selected.length = 0;
        this.loadAppServers();
    }

    private loadAppServers() {
        this.appServersRefreshing = true;
        this.getServerLimitsInfo();
        this.appServerService.getAppServers().subscribe(
            appServers => {
                this.loadedAppServers = appServers;
                this.tableDataSource.data = appServers;
                this.setExpandedServer();
                this.loadFiltersFromLocalStorage();
                this.createAnotherFilterFormGroup();
                this.filterServers(this.selectedMainFilterOption);
                this.sortData(this.sort);
                this.appServersRefreshing = false;
                this.setFilterCounter();
                
            },
            err => {
                this.popUpHelper.showErrorPopUp('Servers loading failed');
                this.appServersRefreshing = false;
            }
        );
    }

    public getClassForStateOfPod(pod: Pod) {
        const initState = this.containerStateService.getPodState(pod);
        return 'stateIndicator' + initState + ' stateIndicatorCircle';
    }

    public hiddenActions() {
        const actionsDiv = document.getElementById('actionsDiv');
        if (actionsDiv !== null) {
            actionsDiv.style.display = 'none';
            this.hoverAppServer = null;
        }
    }
    public showActions(event: Event, hoverAppServer: AppServer) {
        this.hoverAppServer = hoverAppServer;
        const actionsDiv = document.getElementById('actionsDiv');
        if (actionsDiv !== null) {
            let tableRow = (event.target as HTMLElement).parentElement;
            while (!(tableRow instanceof HTMLTableRowElement)) {
                tableRow = tableRow.parentElement;
            }
            const sideNavContainer = document.getElementById('sideNavContent') as HTMLDivElement;
            actionsDiv.style.top = tableRow.getBoundingClientRect().top + sideNavContainer.scrollTop - 60 + 'px';
            actionsDiv.style.height = tableRow.getBoundingClientRect().height + 'px';
            actionsDiv.style.display = 'flex';
        }
    }

    public canBeServerAdmin(appServer: AppServer) {
        return this.loginInfoHelper.privilegeAdmin()
            || (this.loginInfoHelper.privilegeOwnServersAdmin()
                && (appServer.serverOwner.mail === this.loginInfoHelper.getLoginUserFromStorage().mail || this.isAdditionalOwner(appServer)));
    }


    isAdditionalOwner(appServer: AppServer) {
        const user: PortalUser = this.loginInfoHelper.getLoginUserFromStorage() ;
        if (!user.listOfAdditionalAppServers) {
            return false;
        }
        
        for (let addtionalAppServer of user.listOfAdditionalAppServers) {
            if(addtionalAppServer === appServer.serverName) {
                return true;
            }
        }
        return false;
    }


    tagAsPaymentDateExpired(appServer: AppServer): boolean {
        const dateWithoutTime = new Date();
        dateWithoutTime.setHours(0, 0, 0, 0);
        const formatedDate = new Date(appServer.paymentEndDate);
        return appServer.podInfo !== null &&
            appServer.paymentEndDate !== null &&
            formatedDate < dateWithoutTime;
    }

    tagAsServertDateExpired(appServer: AppServer, includeNotRunning?: boolean): boolean {
        const dateForNotificationBeforeExpire = new Date();
        dateForNotificationBeforeExpire.setDate(dateForNotificationBeforeExpire.getDate() + this.notifyDaysBefore);
        const formatedDate = new Date(appServer.serverEndDate);
    
        return appServer.serverEndDate && (includeNotRunning || appServer.podInfo)
            && dateForNotificationBeforeExpire >= formatedDate;
    }


    private checkSameVersions(appServers: AppServer[], majorVersion: string): boolean {
        const foundAppServer = appServers.find(appServer => appServer.majorVersion !== majorVersion);
        return foundAppServer === undefined ? true : false;
    }
   
    sortData(sort: Sort) {
        this.sort = sort;
        if (sort === null) {
            this.tableDataSource.data = this.tableDataSource.data.sort((a, b) => {
                return this.compare(new Date(a.serverStartDate).getTime(), new Date(b.serverStartDate).getTime(), false);
            });
            return;
        }
        if (sort === null || !sort.active || sort.direction === '') {
            this.tableDataSource.data = this.tableDataSource.data.sort((a, b) => {
                return this.compare(new Date(a.serverStartDate).getTime(), new Date(b.serverStartDate).getTime(), false);
            });
            return;
        }

        this.tableDataSource.data = this.tableDataSource.data.sort((a, b) => {
            const isAsc = sort.direction === 'asc';
            switch (sort.active) {
                case 'name': return this.compare(a.serverName, b.serverName, isAsc);
                case 'mtextVersion': return this.compare(a.majorVersion + a.minorVersion, b.majorVersion + b.minorVersion, isAsc);
                case 'serverEndDate': return this.compare(a.serverEndDate.toString(), b.serverEndDate.toString(), isAsc);
                case 'paymentEndDate': return this.compare(a.paymentEndDate.toString(), b.paymentEndDate.toString(), isAsc);
                case 'namespace': return this.compare(a.namespace, b.namespace, isAsc);
                default: return 0;
            }
        });
    }
    private compare(a: number | string, b: number | string, isAsc: boolean) {
        return (a < b ? -1 : 1) * (isAsc ? 1 : -1);
    }

    private getServerLimitsInfo() {
        this.appServerService.getServerCountLimits().subscribe( data => {
            this.maxRunningUserServers = data[ServerLimitLabels.MAX_RUNNING_USER_SERVERS];
            this.maxCreatedUserServers = data[ServerLimitLabels.MAX_CREATED_USER_SERVERS];
            this.actualRunningUserServers = data[ServerLimitLabels.ACTUAL_RUNNING_USER_SERVERS];
            this.actualCreatedUserServers = data[ServerLimitLabels.ACTUAL_CREATED_USER_SERVERS];
            this.maxRunningAllServers = data[ServerLimitLabels.MAX_RUNNING_ALL_SERVERS];
            this.maxCreatedAllServers = data[ServerLimitLabels.MAX_CREATED_ALL_SERVERS];
            this.actualRunningAllServers = data[ServerLimitLabels.ACTUAL_RUNNING_ALL_SERVERS];
            this.actualCreatedAllServers = data[ServerLimitLabels.ACTUAL_CREATED_ALL_SERVERS];
        });
    }

    private getNotifyDaysBefore() {
        this.appServerService.getNotifyDaysBefore().subscribe( data => {
            this.notifyDaysBefore = data;
            const dateForNotificationBeforeExpire = new Date();
            dateForNotificationBeforeExpire.setDate(dateForNotificationBeforeExpire.getDate() - this.notifyDaysBefore);
        });
    }

    setExpandedServer() {
        if (this.expandedElement !== null && this.expandedElement !== undefined) {
            this.expandedElement = this.tableDataSource.data.find(appSever => appSever.serverName === this.expandedElement.serverName);
        }
    }

    expandServerInfo(appServer: AppServer) {
        this.expandedElement = (this.expandedElement != null
            && this.expandedElement.serverName === appServer.serverName) ? null : appServer;
    
    }

/*********************************************        ACTIONS       ************************************************ */
    editAppServer() {
        const dialogRef = this.openEditDialog();
        dialogRef.afterClosed().subscribe(result => {
            if (result) {
                this.popUpHelper.showPopUp('Application server info(' + result.serverName + ') will be updated');
                this.appServerService.editAppServer(result).subscribe(
                    data => {
                        this.loadAppServers();
                        this.popUpHelper.showPopUp('Application server info (' + result.serverName + ') updated');
                    },
                    error => {
                        this.openInfoDialog('Error updating app server', error.error);
                    }
                );
            }
        });
    }

    backUpAppServer() {
        this.openBackupSelectionDialog(this.hoverAppServer);
    }


    deleteAppServer(checkBoxSelection?: boolean) {
        const selectedAppServers = this.getSelectedAppServers(checkBoxSelection);
        let dialogRef = null;

        this.appServerService.getServersWithTraining(selectedAppServers)
            .subscribe(
                (data) => {
                    if (data === null) {
            
                        if (this.loginInfoHelper.privilegeAdmin()) {
                            dialogRef = this.openConfirmDialog('delete', selectedAppServers);
                        } else {
                            dialogRef = this.openSubmitDialog(
                                'Confirm app servers deletion',
                                'Application servers (' + selectedAppServers.length + ') will be permanently deleted includíng volumes. Are you sure?');
                        }
                
                
                        dialogRef.afterClosed().subscribe(result => {
                            if (result) {
                                this.popUpHelper.showPopUp('Application servers (' + selectedAppServers.length + ') will be deleted');
                                this.appServerService.deleteAppServer(selectedAppServers).subscribe(
                                    data => this.selected.length = 0,
                                    error => this.popUpHelper.showErrorPopUp('Error deleting app server', error)
                                );
                            }
                        });
                    } else {
                        this.openTrainingDialog('App server has training', 'App server/s has associated training, you have to delete ', data);
                        return;
                    }

                }
        )
    }

    suspendAppServer(checkBoxSelection?: boolean) {
        let selectedAppServers = this.getSelectedAppServers(checkBoxSelection);
        selectedAppServers = selectedAppServers.filter(appServer => appServer.podInfo !== null);
        const dialogRef = this.openSubmitDialog(
            'Server pause confirmation',
            'Application servers (' + selectedAppServers.length + ') will be suspended. Volumes will be retained.');
        dialogRef.afterClosed().subscribe(result => {
            if (result) {
                this.popUpHelper.showPopUp('Application servers (' + selectedAppServers.length + ') will be suspended');
                this.appServerService.suspendAppServer(selectedAppServers).subscribe(
                    data => this.reloadAppServers(),
                    error => this.popUpHelper.showErrorPopUp('App server suspend error', error)
                );
            }
        });
    }

    restartAppServer(checkBoxSelection?: boolean) {
        const selectedAppServers = this.getSelectedAppServers(checkBoxSelection);

        if (!this.allServersWithRunningPod(selectedAppServers)) {
            const message = 'Restart can be performed only on unstopped servers.';
            this.openInfoDialog('Restart error', message);
            return;
        }
        const dialogRef = this.openSubmitDialog(
            'Confirm app server restart',
            'Application servers (' + selectedAppServers.length + ') will be restarted.');
        dialogRef.afterClosed().subscribe(result => {
            if (result) {
                this.popUpHelper.showPopUp('Application servers (' + selectedAppServers.length + ') will restart');
                this.appServerService.restartAppServer(selectedAppServers).subscribe(
                    data => this.reloadAppServers(),
                    error => this.popUpHelper.showErrorPopUp('App server restart error', error)
                );
            }
        });
    }

    runAppServer(checkBoxSelection?: boolean) {
        let selectedAppServers = this.getSelectedAppServers(checkBoxSelection);
        selectedAppServers = selectedAppServers.filter(appServer => appServer.podInfo === null);
        const expiredServers: AppServer[] = [];
        selectedAppServers.forEach( appServer => {
            if (this.tagAsServertDateExpired(appServer, true)) {
                expiredServers.push(appServer);
            }
        });
        if (expiredServers.length > 0) {
            this.openExpiredServersInfoDialog(expiredServers);
        }

        this.popUpHelper.showPopUp('Application servers (' + selectedAppServers.length + ') will start');
        this.appServerService.runAppServer(selectedAppServers).subscribe(
            data => this.reloadAppServers(),
            error => {
                if (error.status === 406) {
                    this.openInfoDialog('Server limit exceeded', 'The limit for running servers has been exceeded');
                }
                this.popUpHelper.showErrorPopUp('App server startup error', error);
            }
        );
    }

    changeSeriemVersion(checkBoxSelection?: boolean) {
        const selectedAppServers = this.getSelectedAppServers(checkBoxSelection);
        if (!this.allServersWithRunningPod(selectedAppServers)) {
            const message = 'Vesion change can be performed only on unstopped servers.';
            this.openInfoDialog('Vesion change error', message);
            return;
        }

        const majorVersion = selectedAppServers[0].majorVersion;
        if (this.checkSameVersions(selectedAppServers, majorVersion)) {
            
                const dialogRef = this.openVersionSelectionDialog(majorVersion);
                dialogRef.afterClosed().subscribe(minorVersion => {
                    if (minorVersion) {
                        if (this.checkLesserVersion(minorVersion, selectedAppServers[0])) {
                            const confirmRef = this.openSubmitDialog(
                                'Confirm app server downgrade',
                                "You're about to downgrade a server, which could result in some data loss. Are you sure?");
                            confirmRef.afterClosed().subscribe(result => {
                            if (result) {
                                this.changeChangeAppServerVersion(selectedAppServers, minorVersion);
                            }});
                        } else {
                            this.changeChangeAppServerVersion(selectedAppServers, minorVersion);
                        }
                                           } 
                });
        } else {

        }
    }
    
    checkLesserVersion(minorVersion: string, selectedAppServer: AppServer) {
        const splittedMinorVersion = minorVersion.split("-");
        const splittedServerMinorVersion = selectedAppServer.minorVersion.split("-");

        if (+splittedServerMinorVersion[0] > +splittedMinorVersion[0]) { 
            return true;
        } 
        if(splittedServerMinorVersion.length == 1) { // No '-' => no hotfix or anything else
            return false;
        } else if (splittedMinorVersion.length == 1) {
            return true;
        }
        return +(splittedServerMinorVersion[1].replace("hotfix", "")) > +(splittedMinorVersion[1].replace("hotfix", ""));
    }


    changeChangeAppServerVersion(selectedAppServers: AppServer[], minorVersion: any) {
        this.popUpHelper.showPopUp('Mtext version (' + selectedAppServers.length + ' servers)will be changed');
        selectedAppServers.forEach(appServer => {
        appServer.minorVersion = minorVersion;
        this.appServerService.changeSeriemVersion(appServer).subscribe(
                data => { },
                error => this.popUpHelper.showErrorPopUp('Version change error')
        );
     });    
    }

/*********************************************        DIALOGS       ************************************************ */
    public showRestoreDialog(checkBoxSelection?: boolean) {
        const selectedServers = this.getSelectedAppServers(checkBoxSelection);

        if (selectedServers.length === 0) {
            this.openInfoDialog('No server selected', 'Select running servers to perform restore');
            return;
        }

        const notRunningServers = selectedServers.filter(appServer => {
            return appServer.podInfo === null;
        });

        if (notRunningServers.length === 0) {
            this.openResotoreDialog(selectedServers);
        } else {
            const message = 'Restore can be performed only for running servers. ';
            this.openInfoDialog('Restore error', message);
        }

    }

    public openExpiredServersInfoDialog(appServers: AppServer[]) {
        const plural = appServers.length > 1 ? 's' : '';

        const message = 'Server' + plural + ' (' + appServers.map(appServer => appServer.serverName).toString()
            + ') expired. Server' + plural + '  will start, but the end date needs to be extended.' ;
        this.openInfoDialog('Servers expired', message);
    }

    private openConfirmDialog(actionName: string, appServers: AppServer[]): MatDialogRef<ConfirmActionDialogComponent, any> {
        return this.dialog.open(ConfirmActionDialogComponent, {
            width: '450px',
            data: { actionName, appServers}
        });
    }

    openPage(url: string) {
        window.open('https://' + url);
    }

    private allServersWithRunningPod(selectedAppServers: AppServer[]) {
        const foundAppServer = selectedAppServers.find(appServer => appServer.podInfo === null);
        return foundAppServer === undefined;
    }

    private getSelectedAppServers(checkBoxSelection: boolean): AppServer[] {
        if (checkBoxSelection) {
            return this.selected;
        } else {
            return [this.hoverAppServer];
        }
    }

    private openEditDialog() {
        return this.dialog.open(EditAppServerDialogComponent, {
            width: '500px',
            data: this.hoverAppServer,
            disableClose: true
        });
    }

    private openResotoreDialog(selectedAppServers: AppServer[]): void {
        this.dialog.open(RestoreDialogComponent, {
            width: '500px',
            panelClass: 'restore-dialog',
            data: { selectedServers: selectedAppServers, availableServers: this.loadedAppServers },
            disableClose: true
        });
    }

    private openInfoDialog(title: string, message: string): void {
        this.dialog.open(InfoDialogComponent, {
            width: '450px',
            data: { title, message }
        });
    }

    private openTrainingDialog(title: string, message: string, listOfTrainings: Training[]): void {
        this.dialog.open(TrainingDeleteDialogComponent, {
            width: '450px',
            data: { title, message, listOfTrainings}
        });
    }

    private openSubmitDialog(title: string, message: string): MatDialogRef<SubmitDialogComponent, any> {
        return this.dialog.open(SubmitDialogComponent, {
            width: '450px',
            data: { title, message }
        });
    }

    private openVersionSelectionDialog(version: string): MatDialogRef<SelectVersionDialogComponent, any> {
        return this.dialog.open(SelectVersionDialogComponent, {
            width: '350px',
            data: version
        });
    }

    private openBackupSelectionDialog(appServer: AppServer): void {
        this.dialog.open(BackupSelectionDialogComponent, {
            width: '450px',
            data: appServer,
            autoFocus: false
        });
    }
    // ************************************************ SELECTION METHODS *********************************************/
    isAllSelected() {
        const numSelected = this.selected.length;
        const numServersToSelect = this.tableDataSource.data.filter(
            server => server.serverOwner !== null && this.canBeServerAdmin(server)).length;
        return numSelected === numServersToSelect;
    }

      /** Selects all rows if they are not all selected; otherwise clear selection. */
    masterToggle() {
        this.isAllSelected() ? this.selected.length = 0 : this.tableDataSource.data.forEach(row => {
            if (row.serverOwner !== null && this.canBeServerAdmin(row) && !this.isSelected(row)) {
                this.selected.push(row);
            }
        });
    }

    toggleServer(appServerToggle: AppServer) {
        if (this.isSelected(appServerToggle)) {
            this.deselectServer(appServerToggle);
        } else {
            this.selected.push(appServerToggle);
        }
    }

    deselectServer(appServerToDeselect: AppServer) {
        const tmpAppServer = this.selected.filter(appServer => appServer.serverName === appServerToDeselect.serverName)[0];
        const index: number = this.selected.indexOf(tmpAppServer);
        this.selected.splice(index, 1);
    }

    isSelectionEmpty() {
        return this.selected.length === 0;
    }

    private filterSelection(appServers: AppServer[]) {
        const selectedServers = this.selected;
        selectedServers.forEach(selectedAppServer => {
            if (appServers.find(appServer => appServer.serverName === selectedAppServer.serverName) === undefined) {
                this.deselectServer(selectedAppServer);
            }
        });
    }

    isSelected(appServer: AppServer) {
        return this.selected.find(selectedAppServer => selectedAppServer.serverName === appServer.serverName);
    }
// ******************************************* FILTER METHODS *********************************************************/
    anotherFiltersToggle() {
        this.anotherFiltersExpanded = !this.anotherFiltersExpanded;
        if (this.anotherFiltersExpanded && this.anotherFilterForm === null) {
            this.namespaceList = this.loadedAppServers
            .map(server => server.namespace)
            .filter((namespace, index, self) => index === self.indexOf(namespace));
            this.ownerNameList = this.loadedAppServers
                .filter(server => server.serverOwner !== null)
                .map(server => server.serverOwner.displayName)
                .filter((owner, index, self) => owner !== null && index === self.indexOf(owner));
            this.serverNameList = this.loadedAppServers
                .map(server => server.serverName)
                .filter((serverName, index, self) => serverName !== null && index === self.indexOf(serverName));

            const namespaceFormControl = new UntypedFormControl(this.anotherFilterOptions.get(AnotherFilterOption.Namespace));
            this.filteredNamespaceList = namespaceFormControl.valueChanges.pipe(
                startWith(''),
                map(value => this.filterOptions(value, this.namespaceList))
            );
            const ownerFormControl = new UntypedFormControl(null);
            this.filteredOwnerList = ownerFormControl.valueChanges.pipe(
                startWith(''),
                map(value => this.filterOptions(value, this.ownerNameList)),
            );
            const serverFormControl = new UntypedFormControl(this.anotherFilterOptions.get(AnotherFilterOption.ServerName));
            this.filteredServerList = serverFormControl.valueChanges.pipe(
                startWith(''),
                map(value => this.filterOptions(value, this.serverNameList)),
            );
        }
    }

    disableAnotherFilterOptions(anotherFilterOption: AnotherFilterOption) {
        this.anotherFilterOptions.set(anotherFilterOption, null);
        switch (anotherFilterOption) {
            case AnotherFilterOption.ServerName:
                this.anotherFilterForm.get('serverFormControl').setValue('');
                break;
            case AnotherFilterOption.Namespace:
                this.anotherFilterForm.get('namespaceFormControl').setValue('');
                break;
            case AnotherFilterOption.Owner:
                this.anotherFilterForm.get('ownerFormControl').setValue('');
                break;
            case AnotherFilterOption.OnlyMyServers:
                this.anotherFilterForm.get('onlyMyServersFormControl').setValue(false);
                break;
            case AnotherFilterOption.NotMissingInDB:
                this.anotherFilterForm.get('notMissServersFormControl').setValue(false);
                break;

        }
        this.filterServers(this.selectedMainFilterOption);
    }

    private createAnotherFilterFormGroup() {
        this.namespaceList = this.loadedAppServers
            .map(server => server.namespace)
            .filter((namespace, index, self) => index === self.indexOf(namespace));
        this.ownerNameList = this.loadedAppServers
            .filter(server => server.serverOwner !== null)
            .map(server => server.serverOwner.displayName)
            .filter((owner, index, self) => owner !== null && index === self.indexOf(owner));
        this.serverNameList = this.loadedAppServers
            .map(server => server.serverName)
            .filter((server, index, self) => server !== null && index === self.indexOf(server));
        
        const namespaceFormControl = new UntypedFormControl(this.anotherFilterOptions.get(AnotherFilterOption.Namespace));
        this.filteredNamespaceList = namespaceFormControl.valueChanges.pipe(
            startWith(''),
            map(value => this.filterOptions(value, this.namespaceList))
        );
        const ownerFormControl = new UntypedFormControl(this.anotherFilterOptions.get(AnotherFilterOption.Owner));
        this.filteredOwnerList = ownerFormControl.valueChanges.pipe(
            startWith(''),
            map(value => this.filterOptions(value, this.ownerNameList)),
            );


        const serverFormControl = new UntypedFormControl(this.anotherFilterOptions.get(AnotherFilterOption.ServerName));

        this.filteredServerList = serverFormControl.valueChanges.pipe(
            startWith(''),
            map(value => this.filterOptions(value, this.serverNameList)),
        );


        const onlyMyServersFormControl = new UntypedFormControl(this.anotherFilterOptions.get(AnotherFilterOption.OnlyMyServers));
        const notMissServersFormControl = new UntypedFormControl(this.anotherFilterOptions.get(AnotherFilterOption.NotMissingInDB));
        serverFormControl.valueChanges.subscribe(
            value => this.changeAnotherFilterOption(AnotherFilterOption.ServerName, value));
        namespaceFormControl.valueChanges.subscribe(
            value => this.changeAnotherFilterOption(AnotherFilterOption.Namespace, value));
        ownerFormControl.valueChanges.subscribe(
            value => this.changeAnotherFilterOption(AnotherFilterOption.Owner, value));
        onlyMyServersFormControl.valueChanges.subscribe(
            value => this.changeAnotherFilterOption(AnotherFilterOption.OnlyMyServers, value));
        notMissServersFormControl.valueChanges.subscribe(
            value => this.changeAnotherFilterOption(AnotherFilterOption.NotMissingInDB, value));
        this.anotherFilterForm = new UntypedFormGroup({
            serverFormControl,
            namespaceFormControl,
            ownerFormControl,
            onlyMyServersFormControl,
            notMissServersFormControl
        });
        
        this.serverNameFilter = localStorage.getItem("filterServerName");

        if (this.serverNameFilter && this.serverNameFilter !== '') {
            this.anotherFiltersToggle();
            serverFormControl.setValue(this.serverNameFilter)
        }
        localStorage.removeItem("filterServerName")

    }

    private changeAnotherFilterOption(anotherFilterOption: AnotherFilterOption, value: string) {
        this.anotherFilterOptions.set(anotherFilterOption, value);
        this.setFilterCounter();
        this.filterServers(this.selectedMainFilterOption);
    }

    private applyAnotherFilters(): AppServer[] {
        let filteredServer = this.loadedAppServers;
        this.anotherFilterOptions.forEach((value, key) => {
            switch (key) {
                case AnotherFilterOption.ServerName:
                    if (value !== null && value !== '') {
                        filteredServer = filteredServer.filter(server => server.serverName.includes(value));
                    }
                    break;

                case AnotherFilterOption.Namespace:
                    if (value !== null && value !== '') {
                        filteredServer = filteredServer.filter(server => server.namespace === value);
                    }
                    break;
                case AnotherFilterOption.Owner:
                    if (value !== null && value !== '') {
                        filteredServer = filteredServer.filter(server => server.serverOwner !== null &&
                            server.serverOwner.displayName === value);
                    }
                    break;
                case AnotherFilterOption.OnlyMyServers:
                    if (value) {
                         const loginUsername = this.loginInfoHelper.getLoginUserFromStorage().displayName;
                         filteredServer = filteredServer.filter(server => server.serverOwner !== null
                             && server.serverOwner.displayName === loginUsername);
                    }
                    break;
                case AnotherFilterOption.NotMissingInDB:
                    if (value) {
                        filteredServer = filteredServer.filter(server => server.serverOwner !== null);
                    }
            }
        });
        return filteredServer;
    }

    private filterOptions(value: string, list: string[]): string[] {
        const filterValue = value.toLowerCase();
        return list.filter(item => item.toLowerCase().includes(filterValue));
    }

    filterServers(optionKey: MainFilterOption) {
        this.selectedMainFilterOption = optionKey;
        this.saveFiltersToLocalStorage();
        const filtederServers = this.applyAnotherFilters();
        this.tableDataSource.data = this.getFilteredServers(optionKey, filtederServers);
        this.sortData(this.sort);
        this.filterSelection(this.tableDataSource.data);
    }

    private getFilteredServers(optionKey: MainFilterOption, serversToFilter): AppServer[] {
        switch (optionKey) {
            case MainFilterOption.ShowAll:
                return serversToFilter;
            case MainFilterOption.RunningServers:
                return this.filterRunningServers(serversToFilter);
            case MainFilterOption.LoadingServers:
                return this.filterLoadingServers(serversToFilter);
            case MainFilterOption.StoppedServers:
                return this.filterStoppedServers(serversToFilter);
            case MainFilterOption.ExpiredServers:
                return this.filterExpiredServers(serversToFilter);
            case MainFilterOption.ExpiredPayments:
                return this.filterExpiredPaymentServers(serversToFilter);
            case MainFilterOption.PaidServers:
                return this.filterPaidServers(serversToFilter);
            case MainFilterOption.Failed:
                return this.filterFailedServers(serversToFilter);
            case MainFilterOption.MissingInDB:
                return this.filterMissingInDB(serversToFilter);
        }
    }

    private addMainFilterOptions() {
        this.filterServersOptions.set(MainFilterOption.ShowAll, { title: 'Show all', count: 0 });
        this.filterServersOptions.set(MainFilterOption.RunningServers, { title: 'Running', count: 0 });
        this.filterServersOptions.set(MainFilterOption.LoadingServers, { title: 'Loading', count: 0 });
        this.filterServersOptions.set(MainFilterOption.StoppedServers, { title: 'Stopped', count: 0 });
        this.filterServersOptions.set(MainFilterOption.ExpiredServers, { title: 'Expired servers', count: 0 });
        this.filterServersOptions.set(MainFilterOption.ExpiredPayments, { title: 'Expired payments', count: 0 });
        this.filterServersOptions.set(MainFilterOption.PaidServers, { title: 'Paid', count: 0 });
        this.filterServersOptions.set(MainFilterOption.Failed, { title: 'Failed', count: 0 });
        this.filterServersOptions.set(MainFilterOption.MissingInDB, { title: 'Others', count: 0 });
    }

    private saveFiltersToLocalStorage() {
        localStorage.setItem('app_server_view_main_filter', JSON.stringify(this.selectedMainFilterOption));
        const jsonObject = {};
        this.anotherFilterOptions.forEach((value, key) => {
            jsonObject[key] = value;
        });
        localStorage.setItem('app_server_view_another_filters', JSON.stringify(jsonObject));
    }

    private loadFiltersFromLocalStorage() {
        const mainFilterOption = JSON.parse(localStorage.getItem('app_server_view_main_filter'));
        if (mainFilterOption !== null) {
            this.selectedMainFilterOption = mainFilterOption;
        }

        const anotherFilterOptionsJSON = JSON.parse(localStorage.getItem('app_server_view_another_filters'));

        if (anotherFilterOptionsJSON !== null) {
            this.anotherFilterOptions =  new Map<AnotherFilterOption, string>();
            Object.keys(anotherFilterOptionsJSON);
            for (const value in anotherFilterOptionsJSON) {
                if (anotherFilterOptionsJSON.hasOwnProperty(value)) {
                    this.anotherFilterOptions.set(value as AnotherFilterOption, anotherFilterOptionsJSON[value]);
                }
            }
        }
    }

    private setFilterCounter() {
        const filtederServers = this.applyAnotherFilters();
        this.filterServersOptions.forEach((value, key) => {
            value.count = this.getFilteredServers(key, filtederServers).length;
        });
    }

    private filterRunningServers(appServers: AppServer[]) {
        return appServers.filter(appServer => appServer.podInfo !== null && appServer.serverOwner !== null);
    }

    private filterLoadingServers(appServers: AppServer[]) {
        return appServers.filter(appServer => appServer.podInfo !== null && appServer.serverOwner !== null
            && appServer.podInfo.state === ContainerStateEnum.CONTAINER_LOADING);
    }

    private filterStoppedServers(appServers: AppServer[]) {
        return appServers.filter(appServer => appServer.podInfo === null);
    }

    private filterExpiredServers(appServers: AppServer[]) {
        return appServers.filter(appServer => this.tagAsServertDateExpired(appServer));
    }

    private filterExpiredPaymentServers(appServers: AppServer[]) {
        return appServers.filter(appServer => this.tagAsPaymentDateExpired(appServer));
    }

    private filterPaidServers(appServers: AppServer[]) {
        return appServers.filter(appServer => appServer.paymentEndDate !== null && appServer.serverOwner !== null);
    }

    private filterMissingInDB(appServers: AppServer[]) {
        return appServers.filter(appServer => appServer.serverOwner === null);
    }

    private filterFailedServers(appServers: AppServer[]) {
        return appServers.filter(appServer => appServer.podInfo !== null
            && (appServer.podInfo.state === this.POD_FAILED
                || (appServer.podInfo.state === ContainerStateEnum.CONTAINER_LOADING && appServer.podInfo.runningTime > 1000 * 60 * 20)));
    }

}
