import { Component, Inject, OnInit } from '@angular/core';
import { DialogData } from '../info-dialog/info-dialog.component';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';
import { CookieService } from 'ngx-cookie-service';
import { getHash } from '../../../app.component'
import { AppComponent } from 'src/app/app.component';
import { Annoucement } from 'src/app/models/annoucement.model';
import { Severity } from 'src/app/services/announcement.service';

@Component({
  selector: 'app-annoucement-dialog',
  templateUrl: './annoucement-dialog.component.html',
  styleUrls: ['./annoucement-dialog.component.scss']
})
export class AnnoucementDialogComponent implements OnInit {
  colorMapping = {
    SEVERE : "severe",
    WARNING : "warn",
    INFO : "info",
    CONFIG : "config"
  }

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: Array<Annoucement>,
    // private cookieService: CookieService,
    

  ) { }

  ngOnInit(): void {
  }



}
