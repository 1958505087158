import { Component, OnInit } from '@angular/core';
import { VolumeService } from 'src/app/services/volume.service';
import { VolumeInfo } from 'src/app/models/volume-info.model';

@Component({
  selector: 'app-volumes-view',
  templateUrl: './volumes-view.component.html',
  styleUrls: ['./volumes-view.component.scss']
})
export class VolumesViewComponent implements OnInit {
    volumeList: VolumeInfo[] = null;
    public displayedColumns: string[] = ['limit', 'size', 'name', 'isAppServer', 'isUisng'];
    private loadedVolumes: VolumeInfo[] = null;
    isShowAllVolumes = true;
    isShowAppVolumes = false;
    isShowOtherVolumes = false;
    totalVolumeSpace = 0;
    maxVolumeSpace = 0;
    usedAppSeverVolumesSpace = 0;
    notUsedAppSeverVolumesSpace = 0;
    otherVolumesSpace = 0;

    constructor(
        private volumeService: VolumeService
    ) { }

    ngOnInit(): void {
        this.loadVolumeList();
        this.loadVolumeSizeLimit();
        this.loadVolumeSizeTotal();
    }

    private loadVolumeList() {
        this.volumeService.getVolumes().subscribe(
            data => {
                data = data.sort((volume1, volume2) => volume2.size - volume1.size);
                this.volumeList = data;
                this.loadedVolumes = data;
                data.forEach(volume => {
                    if (volume.isAppServer) {
                        if (volume.isUsing) {
                            this.usedAppSeverVolumesSpace += volume.size;
                        } else {
                            this.notUsedAppSeverVolumesSpace += volume.size;
                        }
                    } else {
                        this.otherVolumesSpace += volume.size;
                    }
                });
            }
        );
    }

    private loadVolumeSizeLimit() {
        this.volumeService.getVolumeSizeLimit().subscribe(
            data => {
                this.maxVolumeSpace = data;
            }
        );
    }

    private loadVolumeSizeTotal() {
        this.volumeService.getVolumeSizeTotal().subscribe(
            data => {
                this.totalVolumeSpace = data;
            }
        );
    }

    gerPercentageUsage(volumeSize: number) {
        return volumeSize / 1024 / this.maxVolumeSpace * 100;
    }



    showAllVolumes() {
        this.isShowAllVolumes = true;
        this.isShowAppVolumes = false;
        this.isShowOtherVolumes = false;
        this.volumeList = this.loadedVolumes;
    }

    showAppVolumes() {
        this.isShowAllVolumes = false;
        this.isShowAppVolumes = true;
        this.isShowOtherVolumes = false;
        this.volumeList = this.loadedVolumes.filter(volumeInfo => volumeInfo.isAppServer);
    }

    showOtherVolumes() {
        this.isShowAllVolumes = false;
        this.isShowAppVolumes = false;
        this.isShowOtherVolumes = true;
        this.volumeList = this.loadedVolumes.filter(volumeInfo => !volumeInfo.isAppServer);
    }

}
