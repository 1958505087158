import { Component, OnInit, Inject } from '@angular/core';
import { BuildInfo, ImageRegistryService } from 'src/app/services/image-registry.service';
import { PopupHelper } from 'src/app/helpers/pop-up-helper';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';

@Component({
  selector: 'app-mtext-builds-state-dialog',
  templateUrl: './mtext-builds-state-dialog.component.html',
  styleUrls: ['./mtext-builds-state-dialog.component.scss']
})
export class MtextBuildsStateDialogComponent implements OnInit {
    builds: BuildInfo[];
    public displayedColumns: string[] = ['state', 'number', 'date', 'url'];
    buildsLoading = true;
    selectedMajorVersion: string;
    constructor(
        private imageRegistryService: ImageRegistryService,
        private popUpHelper: PopupHelper,
        @Inject(MAT_DIALOG_DATA) public majorVersions: string[],
    ) {
        this.selectedMajorVersion = majorVersions[0];
    }

    ngOnInit(): void {
        this.loadBuildHistory();
    }

    onVersionChange(selectedVersion) {
        this.selectedMajorVersion = selectedVersion;
        this.loadBuildHistory();

    }

    loadBuildHistory() {
        this.buildsLoading = true;
        this.imageRegistryService.getBuildHistory(this.selectedMajorVersion).subscribe(
            data => {
                this.builds = data;
                this.buildsLoading = false;
            },
            err => {
                console.error(err);
                this.popUpHelper.showErrorPopUp('Builds state loading failed');
                this.buildsLoading = false;
            }
        );
    }

    openConsoleLog(buildInfo: BuildInfo) {
        window.open(buildInfo.url + '/consoleFull');
    }

}
