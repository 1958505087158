import { Component, Inject, OnInit } from '@angular/core';
import { UntypedFormControl } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { PortalUser } from 'src/app/models/portal-user.model';
import { PortaluserService } from 'src/app/services/portal-user.service';
import { LoginInfoHelper } from 'src/app/helpers/login-info-helper';
import { AppServer } from 'src/app/models/app-server.model';
import { Router } from '@angular/router';

@Component({
  selector: 'app-user-selection-dialog',
  templateUrl: './user-selection-dialog.component.html',
  styleUrls: ['./user-selection-dialog.component.scss']
})
export class UserSelectionDialogComponent implements OnInit {
  users: PortalUser[] = null;
  isLoading = false;
  additionalOwners: UntypedFormControl = new UntypedFormControl();

  constructor(
        @Inject(MAT_DIALOG_DATA) public data: UserSelectionDialogData,
        private portalUserService: PortaluserService,
        private dialogRef: MatDialogRef<UserSelectionDialogComponent>,
        private loginInfoHelper: LoginInfoHelper,
        private router: Router,
      ) { 
      if(this.data === null || this.data === undefined) {
        return;
      }
      let appServers = this.data.appServers;
      if (appServers === null || appServers === undefined || appServers.length === 0) {
        return;
      }
      this.loadUsers();
  }

  ngOnInit(): void {
  }

  submitDialog() {
    this.dialogRef.close(this.additionalOwners.value);
  }

  
  disableConfirm() {
    return this.additionalOwners.value === null || this.additionalOwners.value === undefined || this.additionalOwners.value.length === 0 ;
  }

  private loadUsers() {

    const flagAdding = this.data.adding;
    const loadedServers = this.data.appServers;
  
    this.isLoading = true;
    this.portalUserService.getUsers().subscribe(
      (loadedUsers) => {
        this.users = loadedUsers.filter(function(loadedUser) {
          for (let server of loadedServers) {
            if (server.serverOwner.mail === loadedUser.mail) {
              return false;
            }
            for (let additionalOwner of server.listOfAdditionalOwners) {
              if (additionalOwner.mail === loadedUser.mail) {
                return !flagAdding;
              }
            }
          }
          return flagAdding;
        });
        this.users.sort((a, b) => a.displayName.localeCompare(b.displayName));
        
        
        this.isLoading = false;
    },
    (error) => {
      
      this.isLoading = false;
    }
  );
  }

  compareFunction(o1: any, o2: any) {
    return (o1.mail == o2.mail);
   }

}

export interface UserSelectionDialogData {
  title: string;
  adding: boolean;
  appServers: AppServer[];
}
