import { Injectable } from '@angular/core';
import { environment } from '../../environments/environment';
import { HttpClient, HttpParams } from '@angular/common/http';
import { Observable } from 'rxjs';

import { AppServer } from '../models/app-server.model';
import { ContainerStateService } from './container-state.service';
import { map } from 'rxjs/operators';
import { PortalUser } from '../models/portal-user.model';
import { AdditionalOwnership } from '../models/additional-ownership';
import { Training } from '../models/training.model';
export enum ServerLimitLabels {
    MAX_RUNNING_USER_SERVERS = 'maxRunningUserServers',
    MAX_CREATED_USER_SERVERS = 'maxCreatedUserServers',
    ACTUAL_RUNNING_USER_SERVERS = 'actualRunningUserServers',
    ACTUAL_CREATED_USER_SERVERS = 'actualCreatedUserServers',
    MAX_RUNNING_ALL_SERVERS = 'maxRunningAllServers',
    MAX_CREATED_ALL_SERVERS = 'maxCreatedAllServers',
    ACTUAL_RUNNING_ALL_SERVERS = 'actualRunningAllServers',
    ACTUAL_CREATED_ALL_SERVERS = 'actualCreatedAllServers',
}

@Injectable({
    providedIn: 'root'
})
export class AppServerService {
    private APP_SERVER_API = 'appService';
    constructor(private http: HttpClient,
                private containerStateService: ContainerStateService) {}

    public getAppServers(): Observable<AppServer[]> {
        return this.http.get<AppServer[]>(environment.dmePortalApi + this.APP_SERVER_API + '/list').pipe(
            map(appServers => appServers.map(appServer => {
                if (appServer.podInfo) {
                    appServer.podInfo.state = this.containerStateService.getPodState(appServer.podInfo);
                }
                return appServer;
            }))
        );
    }

    public getAllAppServers(): Observable<AppServer[]> {
        return this.http.get<AppServer[]>(environment.dmePortalApi + this.APP_SERVER_API + '/listAll');
    }
 
    public getAppServerStoreInDB(): Observable<AppServer[]> {
        return this.http.get<AppServer[]>(environment.dmePortalApi + this.APP_SERVER_API + '/listFromDB');
    }

    public isExistsAppServer(serverName: string): Observable<boolean> {
        return this.http.get<boolean>(environment.dmePortalApi + this.APP_SERVER_API + '/isExists/' + serverName);
    }

    public isAlreadyDeleted(serverName: string): Observable<boolean> {
        return this.http.get<boolean>(environment.dmePortalApi + this.APP_SERVER_API + '/alreadyDeleted/' + serverName);
    }

    public createAppServer(servers: AppServer[]) {
        return this.http.post<AppServer>(environment.dmePortalApi + this.APP_SERVER_API, servers);
    }

    public deleteAppServer(servers: AppServer[]) {
        return this.http.post(environment.dmePortalApi + this.APP_SERVER_API + '/delete', servers);
    }

    public suspendAppServer(servers: AppServer[]) {
        return this.http.post(environment.dmePortalApi + this.APP_SERVER_API + '/suspend', servers);
    }

    public runAppServer(servers: AppServer[]) {
        return this.http.post(environment.dmePortalApi + this.APP_SERVER_API + '/run', servers);
    }

    public editAppServer(server: AppServer) {
        return this.http.put(environment.dmePortalApi + this.APP_SERVER_API + '/edit', server);
    }

    public assignServers(servers: AppServer[], selectedUsers: PortalUser[]) {
        const additionalOwnership: AdditionalOwnership = new AdditionalOwnership();

        additionalOwnership.appServerDtos = servers;
        additionalOwnership.userDtos = selectedUsers;

        return this.http.post(environment.dmePortalApi + this.APP_SERVER_API + '/addAdditionalOwners', additionalOwnership);
    }
    public unAssignServers(servers: AppServer[], selectedUsers: PortalUser[]) {
        const additionalOwnership: AdditionalOwnership = new AdditionalOwnership();

        additionalOwnership.appServerDtos = servers;
        additionalOwnership.userDtos = selectedUsers;

        return this.http.post(environment.dmePortalApi + this.APP_SERVER_API + '/unAddAdditionalOwners', additionalOwnership);
    }

    public changeSeriemVersion(server: AppServer) {
        return this.http.post(environment.dmePortalApi + this.APP_SERVER_API + '/changeVersion', server);
    }

    public startAppServer(servers: AppServer[]) {
        return this.http.post(environment.dmePortalApi + this.APP_SERVER_API + '/start', servers);
    }

    public restartAppServer(servers: AppServer[]) {
        return this.http.post(environment.dmePortalApi + this.APP_SERVER_API + '/restart', servers);
    }

    public getDomainPostfixList(): Observable<string[]> {
        return this.http.get<string[]>(environment.dmePortalApi + this.APP_SERVER_API + '/domainPostfixList');
    }

    public getServerCountLimits(): Observable<Map<string, number>> {
        return this.http.get<Map<string, number>>(environment.dmePortalApi + this.APP_SERVER_API + '/limits');
    }

    public getPartners(): Observable<string[]> {
        return this.http.get<string[]>(environment.dmePortalApi + this.APP_SERVER_API + '/partners');
    }

    public getBillingServers(dateFrom: Date, dateTo: Date): Observable<AppServer[]> {
        const params = new HttpParams()
            .set('dateFrom', '' + dateFrom.getTime())
            .set('dateTo', '' + dateTo.getTime());
        return this.http.get<AppServer[]>(environment.dmePortalApi + this.APP_SERVER_API + '/billingServers', {params});
    }

    public getServerLog(appServer: AppServer): Observable<any> {
        const params = new HttpParams()
            .set('appServerName', appServer.serverName)

        return this.http.get<any>(environment.dmePortalApi + this.APP_SERVER_API + '/log',
            { responseType: 'blob' as 'json', params });
    }

    public getNotifyDaysBefore() {
        return this.http.get<number>(environment.dmePortalApi + this.APP_SERVER_API + '/notifyDaysBefore');
    }

    public getDefaultWhitelistSourceRange() {
        return this.http.get<string[]>(environment.dmePortalApi + this.APP_SERVER_API + '/whitelistSourceRange');
    }
    
    public getServersWithTraining(appServers: AppServer[]) : Observable<Training[]> {
        return this.http.post<Training[]>(environment.dmePortalApi + this.APP_SERVER_API + '/getWithTraining', appServers);
    }
    
}
