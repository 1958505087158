import { Injectable } from '@angular/core';
import {
    HttpRequest,
    HttpHandler,
    HttpEvent,
    HttpResponse,
    HttpInterceptor
} from '@angular/common/http';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { LoginInfoHelper } from './login-info-helper';

@Injectable()
export class LoginCredencialInterceptor implements HttpInterceptor {
    constructor(private loginInfoHelper: LoginInfoHelper) {}

    intercept(
        request: HttpRequest<any>,
        next: HttpHandler
    ): Observable<HttpEvent<any>> {
        return next.handle(request).pipe(
            map((event: HttpEvent<any>) => {
                if (event instanceof HttpResponse) {
                    this.loginInfoHelper.setUserPrivileges(event.headers.get('privileges'));
                }
                return event;
        }));
    }
}
