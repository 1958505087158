
export class WorkspaceUser {
    workspaceId: string;
    username: string;
    password: string;
    state: string;
    gitURL: string;
    CVS: string;
    runningTime: number;
}

