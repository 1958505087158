import { Component, OnInit, Inject } from '@angular/core';
import { UntypedFormGroup, UntypedFormControl } from '@angular/forms';
import { ImageRegistryService } from 'src/app/services/image-registry.service';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { iif } from 'rxjs';
import { PopupHelper } from 'src/app/helpers/pop-up-helper';

@Component({
  selector: 'app-another-mtext-version-dialog',
  templateUrl: './another-mtext-version-dialog.component.html',
  styleUrls: ['./another-mtext-version-dialog.component.scss']
})
export class AnotherMtextVersionDialogComponent implements OnInit {
    buildRequestInProgress = false;

    anotherVersionEditFormGroup: UntypedFormGroup;
    majorVersionFormControl: UntypedFormControl;
    mtextVersionFormControl: UntypedFormControl;
    omsVersionFormControl: UntypedFormControl;
    constructor(
        private imageRegistryService: ImageRegistryService,
        @Inject(MAT_DIALOG_DATA) public majorVersions: string[],
        private dialogRef: MatDialogRef<AnotherMtextVersionDialogComponent>,
        private popUpHelper: PopupHelper
    ) {
    }

    ngOnInit(): void {
        this.majorVersionFormControl = new UntypedFormControl(this.majorVersions[0]);
        this.mtextVersionFormControl = new UntypedFormControl('');
        this.omsVersionFormControl = new UntypedFormControl();
        this.anotherVersionEditFormGroup = new UntypedFormGroup({
            majorVersionFormControl: this.majorVersionFormControl,
            mtextVersionFormControl: this.mtextVersionFormControl,
            omsVersionFormControl: this.omsVersionFormControl
        });
    }

    get majorVersion() {
        return this.majorVersionFormControl.value;
    }

    build() {
        const mtextVersion = this.majorVersionFormControl.value + '.0.' + this.mtextVersionFormControl.value;
        const omsVersion = this.omsVersionFormControl.value === null || this.omsVersionFormControl.value === ''
            ? '' : this.majorVersionFormControl.value + '.0.' + this.omsVersionFormControl.value;
        this.buildRequestInProgress = true;
        this.imageRegistryService.build(mtextVersion, omsVersion, this.majorVersionFormControl.value).subscribe(
            data => this.dialogRef.close(true),
            err => {
                console.error(err);
                this.popUpHelper.showErrorPopUp('Builds state loading failed');
                this.buildRequestInProgress = false;
            }
        );
    }

}
