import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from 'src/environments/environment';
import { Observable } from 'rxjs';
@Injectable({
    providedIn: 'root'
})
export class NamespaceService {
    private NAMESPACE_API = 'namespace';
    constructor(private http: HttpClient) { }

    public getCustomerNamespaces(): Observable<string[]> {
        return this.http.get<string[]>(environment.dmePortalApi + this.NAMESPACE_API);
    }
}
