import { Component, OnInit } from '@angular/core';
import { LoginInfoHelper } from 'src/app/helpers/login-info-helper';
import { environment } from 'src/environments/environment';
import { AuthService } from 'src/app/services/auth.service';
import { Router } from '@angular/router';
import { ConfigInitService } from 'src/app/services/config-init.service';
declare var require: any;
const mtextCloudImg = require('file-loader!src/assets/mtextCloud.jpg');
@Component({
    selector: 'app-top-nav',
    templateUrl: './top-nav.component.html',
    styleUrls: ['./top-nav.component.scss']
})
export class TopNavComponent implements OnInit {
    readonly mtextCloudImg = mtextCloudImg;
    public environment = environment;
    adminPortalName: string;
    adminPortalVersion: string;
    constructor(
        private authService: AuthService,
        public loginLoginInfoHelper: LoginInfoHelper,
        private router: Router,
        private configService: ConfigInitService,

    ) {
        const adminPortalDelimiter = ";";
        this.configService.getAdminPortalInfo().subscribe(
            (data) => {
                const splitedInfo = data.split(adminPortalDelimiter);

                this.adminPortalName = splitedInfo[0];
                this.adminPortalVersion = splitedInfo[1];


            }, 
            (error) => {
                    
            }
        )
    }

    ngOnInit() {
    }

    logout() {
        this.authService.logout();
    }

    isAws() {
        return localStorage.getItem("isAws") === "true";
    }

    isMCloud4Training(): boolean {
        return this.router.url.split('?')[0]  === '/kwconnect';
    }
}
