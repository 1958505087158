import { Component, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { AnnoucementService, Severity } from 'src/app/services/announcement.service';
import { Annoucement } from 'src/app/models/annoucement.model';
import { Router } from '@angular/router';
import { AppComponent, getHash } from 'src/app/app.component';
import { CookieService } from 'ngx-cookie-service';
import { InfoDialogComponent } from '../dialogs/info-dialog/info-dialog.component';
import { MatDialog } from '@angular/material/dialog';

const today = new Date();
const month = today.getMonth();
const year = today.getFullYear();
  

@Component({
  selector: 'app-announcement-creation',
  templateUrl: './announcement-creation.component.html',
  styleUrls: ['./announcement-creation.component.scss']
})
export class AnnouncementCreationComponent implements OnInit {
  enumEntries = []
  unreadAnnouncements = []
  allAnnouncements = []
  allChosen = true;
  description = new FormControl('', [Validators.required]);
  maxLen = 1000;

  date = new FormGroup({
    start: new FormControl<Date | null>(null, [Validators.required]),
    end: new FormControl<Date | null>(null, [Validators.required]),
  });
  constructor(
    private announcementService: AnnoucementService,
    private router: Router,
    private cookieService: CookieService,
    private dialog: MatDialog,
  ) { }

  ngOnInit(): void {
    
    this.enumEntries = Object.keys(Severity).filter(key => !isNaN(Number(Severity[key])));
    this.announcementService.getPlannedAnnoucements().subscribe(
      data => {          
          this.allAnnouncements = data;
          for(let retrievedAnnouncement of data){
            const cookieName = AppComponent.cookieAnnouncementPrefix + retrievedAnnouncement.dateTo + "_" + getHash(retrievedAnnouncement);

            // Does cookie exist or is cookie unread ? If so, add it into unread 
            if(!this.cookieService.check(cookieName) 
                ||
              this.cookieService.get(cookieName) === "false"
              ){
                this.unreadAnnouncements.push(retrievedAnnouncement);
              }
            }

      },
      error => {
        // alert("We're very sorry there has been issue with retrieving Announcements!")
        // this.dialog.open(InfoDialogComponent, {
        //   width: '450px',
        //   data: { "title" :"Error", "message": error.error},
        // });
      }
    )

  }

  createAnnouncement(): void {
    if(this.description.invalid || this.date.invalid){
      
      this.dialog.open(InfoDialogComponent, {
        width: '450px',
        data: { "title" :"Error", "message": "I'm sorry but you need to fill out every form field!" },
      });
      return;
    }
    let announcement = new Annoucement();  
    // announcement.severity = Severity[this.severity.value];
    announcement.dateFrom = this.date.value.start;
    announcement.dateTo = this.date.value.end;
    announcement.description = this.description.value;

    this.announcementService.addNewAnnouncement(announcement).subscribe(
        data => {
          this.router.navigateByUrl('/list');

        },
        error => {
          this.dialog.open(InfoDialogComponent, {
            width: '450px',
            data: { "title" :"Error", "message": error.error.message },
          });

        }
      )
  }



}
