import { Component, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';

@Component({
    selector: 'app-submit-dialog',
    templateUrl: './submit-dialog.component.html',
    styleUrls: ['./submit-dialog.component.scss']
})
export class SubmitDialogComponent{

    constructor(
        public dialogRef: MatDialogRef<SubmitDialogComponent>,
        @Inject(MAT_DIALOG_DATA) public data: DialogData) {}

}

export interface DialogData {
    title: string;
    message: string;
}
