import { Component, OnInit, Inject, Input } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { MatSnackBarRef, MAT_SNACK_BAR_DATA } from '@angular/material/snack-bar';
import { AnnoucementDialogComponent } from '../../dialogs/annoucement-dialog/annoucement-dialog.component';

@Component({
  selector: 'app-announcement-pop-up',
  templateUrl: './announcement-pop-up.component.html',
  styleUrls: ['./announcement-pop-up.component.scss']
})
export class AnnouncementPopUpComponent implements OnInit {
  public message: string;

  // @Input() hideToggle = true;

  constructor(
    public snackBarRef: MatSnackBarRef<AnnouncementPopUpComponent>,
    @Inject(MAT_SNACK_BAR_DATA) public data: any,
    public dialog: MatDialog,
    
  ) { }

  ngOnInit(): void {
    // const len = this.data.length;
    // this.message = finalMessage;
    // const finalMessage = "" + ((len === 0 ) ? "no unread Announcements" : (len === 1) ? "1 unread Announcement!" : (len + " unread Announcements"));
    
  }

  showAnnoucements(): void {
    this.dialog.open(AnnoucementDialogComponent, {
      data: this.data
    })
    
    // this.hideToggle = false;
    this.snackBarRef.dismiss();
    
  }

}
