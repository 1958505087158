
import { InvoiceItem } from './invoice-item.model';

export class Invoice {
    id: number;
    invoiceNumber: string;
    note: string;

    confirmDate: Date;
    invoiceItemList: InvoiceItem[];

    totalPrice: number;
    serverCount: number;
}
