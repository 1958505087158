import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';
import { HttpClient, HttpParams } from '@angular/common/http';
import { Invoice } from '../models/invoice.model';
import { formatDate } from '@angular/common';

@Injectable({
  providedIn: 'root'
})
export class InvoiceService {
    private INVOICE_API = 'invoice';
    constructor(private http: HttpClient) { }

    public confirmInvoice(invoice: Invoice) {
        return this.http.post(environment.dmePortalApi + this.INVOICE_API + '/confirm', invoice);
    }

    public getInvoices(dateFrom?: Date, dateTo?: Date) {
        let params = new HttpParams();
        if (dateFrom) {
            params = params.set('dateFrom', formatDate(dateFrom, 'dd/MM/yyyy', 'en-US'));
        }
        if (dateTo) {
            params = params.set('dateTo', formatDate(dateTo, 'dd/MM/yyyy', 'en-US'));
        }

        return this.http.get<Invoice[]>(environment.dmePortalApi + this.INVOICE_API + '/list', {params});
    }

    public editInvoice(invoice: Invoice) {
        return this.http.put(environment.dmePortalApi + this.INVOICE_API + '/edit', invoice);
    }

    public deleteInvoice(invoice: Invoice) {
        return this.http.delete(environment.dmePortalApi + this.INVOICE_API + '/delete/' + invoice.id);
    }
}
