import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { EMPTY, Observable } from 'rxjs';
import { map } from 'rxjs/operators';

@Component({
  selector: 'app-error',
  templateUrl: './error.component.html',
  styleUrls: ['./error.component.scss']
})
export class ErrorComponent implements OnInit {
  constructor(private readonly route: ActivatedRoute) {}

  data: Observable<{ code: number; message: string } | undefined> = EMPTY;

  ngOnInit(): void {
    this.data = this.route.data.pipe(
      map(
        (data) =>
          (data.errorData as { code: number; message: string }) || undefined
      )
    );
  }
}
