import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { DirectoryInfo } from 'src/app/models/directory-info.model';
import { WorkspaceUser } from 'src/app/models/workspace-user.model';
import { WorkspaceService } from 'src/app/services/workspace.service';

@Component({
  selector: 'app-workspace-info-dialog',
  templateUrl: './workspace-info-dialog.component.html',
  styleUrls: ['./workspace-info-dialog.component.scss']
})
export class WorkspaceInfoDialogComponent implements OnInit {

  directoryInfo: DirectoryInfo;
  password = true;

  constructor(
    public dialogRef: MatDialogRef<WorkspaceInfoDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: WorkspaceUser,
    workspaceService: WorkspaceService
  ) {
      workspaceService.getDirectoryInfo().subscribe(
        (data) => {
          this.directoryInfo = data;
        }
      )
     }

  ngOnInit(): void {
  }

  onCloseClick(): void {
    this.dialogRef.close();
  }

  openWorkspaceClients() {
    window.open("https://clients.amazonworkspaces.com/", "_blank");
  }
  
  togglePassword() {
    this.password = !this.password;
  }
}


