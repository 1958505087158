import { Injectable } from '@angular/core';
import { UserPrivilegeTypes } from '../services/portal-user.service';
import { PortalUser } from '../models/portal-user.model';

@Injectable({
    providedIn: 'root'
})
export class LoginInfoHelper {
    constructor(
    ) {
    }

    public getLoginUserFromStorage(): PortalUser {
        return JSON.parse(localStorage.getItem('user'));
    }

    public setLoginUserToStorage(user : PortalUser) {
        localStorage.setItem('user', JSON.stringify(user));
    }

    public setUserPrivileges(privileges: string) {
        localStorage.setItem('privileges', privileges);
    }

    public setKWConnectValues(subscriptionValid: string, subscriptionStart: string, subscriptionEnd: string) {
        localStorage.setItem("subscriptionValid", subscriptionValid);
        localStorage.setItem("subscriptionStart", subscriptionStart);
        localStorage.setItem("subscriptionEnd", subscriptionEnd);
    }

    public getUserPrivileges(): string[] {
        const privilegesStr = localStorage.getItem('privileges');

        return privilegesStr.split(',');
    }

    public privilegeAdmin(): boolean {
        return this.getUserPrivileges().includes(UserPrivilegeTypes.ADMIN);
    }

    public privilegeAllServerView(): boolean {
        return this.getUserPrivileges().includes(UserPrivilegeTypes.ALL_SERVER_VIEW);
    }

    public privilegeCustomerServers(): boolean {
        return this.getUserPrivileges().includes(UserPrivilegeTypes.CUSTOMER_SERVERS);
    }

    public privilegeInvoice(): boolean {
        return this.getUserPrivileges().includes(UserPrivilegeTypes.INVOICE);
    }

    public privilegeOwnServersAdmin(): boolean {
        return this.getUserPrivileges().includes(UserPrivilegeTypes.OWN_SERVERS_ADMIN);
    }

    public privilegeWorkspace(): boolean {
        return this.getUserPrivileges().includes(UserPrivilegeTypes.WORKSPACE);
    }

    public privilegeKWConnect(): boolean {
        return this.getUserPrivileges().includes(UserPrivilegeTypes.KWCONNECT);
    }
}
