import { Component, OnInit, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA, MatDialog } from '@angular/material/dialog';
import { AppServer } from 'src/app/models/app-server.model';

@Component({
  selector: 'app-confirm-action-dialog',
  templateUrl: './confirm-action-dialog.component.html',
  styleUrls: ['./confirm-action-dialog.component.scss']
})
export class ConfirmActionDialogComponent{
    confirmText = '';
    errorMessage = '';
    constructor(
        public dialogRef: MatDialogRef<ConfirmActionDialogComponent>,
        @Inject(MAT_DIALOG_DATA) public data: ConfirmDialogData,
        ) {}

    confirm () {
        if(this.data.appServers.length === 1) {
            if (this.confirmText === this.data.appServers[0].serverName) {
                this.dialogRef.close(true);
            } else {
                this.errorMessage= "Invalid confirmation";
            }
        } else {
            if (+this.confirmText === this.data.appServers.length) {
                this.dialogRef.close(true);
            } else {
                this.errorMessage= "Invalid confirmation";
            }
        }
    }

}

export interface ConfirmDialogData {
    actionName: string;
    appServers: AppServer[];
}
