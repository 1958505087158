import { Injectable } from '@angular/core';
import { AppServer } from '../models/app-server.model';
import { HttpParams, HttpClient } from '@angular/common/http';
import { environment } from 'src/environments/environment';

@Injectable({
    providedIn: 'root'
})
export class AppServerOtherDetailsService {
    private APP_SERVER_API = 'appServerOtherDetails';
    constructor(private http: HttpClient) { }

    public getWhitelistSourceRange(appServer: AppServer) {
        const params = new HttpParams()
            .set('serverName', appServer.serverName)
            .set('namespace', appServer.namespace);
        return this.http.get<string[]>(environment.dmePortalApi + this.APP_SERVER_API + '/whitelistSourceRange', {params});
    }

    public getPorts(appServer: AppServer) {
        const params = new HttpParams()
            .set('serverName', appServer.serverName);
        return this.http.get<any>(environment.dmePortalApi + this.APP_SERVER_API + '/ports', {params});
    }

    public getMtextLogLastModified(appServer: AppServer) {
        const params = new HttpParams()
            .set('appServerIpAddress', appServer.podInfo.podIP)
        return this.http.get<Date>(environment.dmePortalApi + this.APP_SERVER_API + '/mtextLogLastModified', {params});
    }

    public activateDbTools(appServer: AppServer) {
        return this.http.post(environment.dmePortalApi + this.APP_SERVER_API + '/activateDbTools', appServer);
    }

    public activateDocumentPortal(appServer: AppServer) {
        return this.http.post(environment.dmePortalApi + this.APP_SERVER_API + '/activateDocumentPortal', appServer);
    }

    public deactivateDbTools(appServer: AppServer) {
        return this.http.post(environment.dmePortalApi + this.APP_SERVER_API + '/deactivateDbTools', appServer);
    }

    public deactivateDocumentPortal(appServer: AppServer) {
        return this.http.post(environment.dmePortalApi + this.APP_SERVER_API + '/deactivateDocumentPortal', appServer);
    }

    public secureWelcomePage(appServer: AppServer) {
        return this.http.post(environment.dmePortalApi + this.APP_SERVER_API + '/secureWelcomePage', appServer);
    }

    public unsecureWelcomePage(appServer: AppServer) {
        return this.http.post(environment.dmePortalApi + this.APP_SERVER_API + '/unsecureWelcomePage', appServer);
    }

    public isWelcomePageSecure(appServer: AppServer) {
        const params = new HttpParams()
            .set('appServerIpAddress', appServer.podInfo.podIP)
        return this.http.get<boolean>(environment.dmePortalApi + this.APP_SERVER_API + '/isWelcomePageSecure', {params});
    }

    public isDbToolsActive(appServer: AppServer) {
        const params = new HttpParams()
            .set('appServerIpAddress', appServer.podInfo.podIP);
        return this.http.get<boolean>(environment.dmePortalApi + this.APP_SERVER_API + '/isDbToolsActive', {params});
    }

    public isDocumentPortalActive(appServer: AppServer) {
        const params = new HttpParams()
            .set('appServerIpAddress', appServer.podInfo.podIP);
        return this.http.get<boolean>(environment.dmePortalApi + this.APP_SERVER_API + '/isDocumentPortalActive', {params});
    }

    public isSecureWelcomePageSAvaible(appServer: AppServer) {
        const params = new HttpParams()
            .set('appServerIpAddress', appServer.podInfo.podIP);
        return this.http.get<boolean>(environment.dmePortalApi + this.APP_SERVER_API + '/isSecureWelcomePageSAvaible', {params});
    }
}
