import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from 'src/environments/environment';
import { Observable } from 'rxjs';
import { PortalUser } from '../models/portal-user.model';

export enum UserPrivilegeTypes {ADMIN = 'ADMIN', ALL_SERVER_VIEW = 'ALL_SERVER_VIEW',
    INVOICE = 'INVOICE', OWN_SERVERS_ADMIN = 'OWN_SERVERS_ADMIN', CUSTOMER_SERVERS = 'CUSTOMER_SERVERS', WORKSPACE="WORKSPACE", KWCONNECT="KWCONNECT"}

@Injectable({
    providedIn: 'root'
})

export class PortaluserService {
    private USER_API = 'user';
    constructor(private http: HttpClient) { }

    public getUsers(): Observable<PortalUser[]> {
        return this.http.get<PortalUser[]>(environment.dmePortalApi + this.USER_API + '/list');
    }

    public getAdmins(): Observable<PortalUser[]> {
        return this.http.get<PortalUser[]>(environment.dmePortalApi + this.USER_API + '/admins');
    }
    
    public getAdminContacts(): Observable<PortalUser[]> {
        return this.http.get<PortalUser[]>(environment.dmePortalApi + this.USER_API + '/adminContacts');
    }

    public updateUser(user: PortalUser): Observable<any> {
        return this.http.post<any>(environment.dmePortalApi + this.USER_API + '/update', user);
    }

    public getMe(): Observable<PortalUser> {
        return this.http.get<PortalUser>(environment.dmePortalApi + this.USER_API + '/me');
    }

    public getKeycloakMe(): Observable<PortalUser> {
        return this.http.get<PortalUser>(environment.dmePortalApi + this.USER_API + '/keycloakMe');
    }
}
