import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
// import { Workspace } from "../models/workspace.model";
import { environment } from "src/environments/environment";
import { Observable } from "rxjs";
import { UserData } from "../models/user-data.model";
import { Course } from "../models/course.model";

@Injectable({
    providedIn: 'root'
})
export class CourseService {
    private COURSE_API = 'course';
    constructor(private http: HttpClient) { }

    public getCourses(): Observable<Course[]> {
        return this.http.get<Course[]>(environment.dmePortalApi + this.COURSE_API + "/list");
    }

    public getCourse(courseName: string): Observable<Course> {
        return this.http.get<Course>(environment.dmePortalApi + this.COURSE_API + "/get/" + courseName);
    }

    public createCourse(course: Course): Observable<Course> {
        return this.http.post<Course>(environment.dmePortalApi + this.COURSE_API + "/create", course);
    }

    public deleteCourse(course: Course): Observable<any> {
        return this.http.delete<any>(environment.dmePortalApi + this.COURSE_API + "/delete/" + course.courseName);
    }

}

