import { Component, OnInit, Inject } from '@angular/core';
import { UntypedFormGroup, UntypedFormControl } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { MatTableDataSource } from '@angular/material/table';
import { EventLog } from 'src/app/models/event-log.model';
import { InvoiceConfirmDialogComponent } from '../invoice-confirm-dialog/invoice-confirm-dialog.component';
import { AppServer } from 'src/app/models/app-server.model';
import { InvoiceItem } from 'src/app/models/invoice-item.model';

@Component({
  selector: 'app-invoice-modify-dialog',
  templateUrl: './invoice-modify-dialog.component.html',
  styleUrls: [ '../../events-log-view/events-log-view.component.scss', './invoice-modify-dialog.component.scss']
})
export class InvoiceModifyDialogComponent implements OnInit {
    invoiceForm: UntypedFormGroup;
    priceFormControl: UntypedFormControl;
    noteFormControl: UntypedFormControl;

    tableDataSource: MatTableDataSource<EventLog>;
    public displayedColumns: string[] = ['eventType', 'eventDate', 'note'];

    constructor(
        public dialogRef: MatDialogRef<InvoiceConfirmDialogComponent>,
        @Inject(MAT_DIALOG_DATA) public data: AppServer,
    ) { }

    ngOnInit() {
        this.priceFormControl = new UntypedFormControl(this.data.invoiceItem.price);
        this.noteFormControl = new UntypedFormControl();
        this.invoiceForm = new UntypedFormGroup({
            priceFormControl: this.priceFormControl,
            noteFormControl: this.noteFormControl
        });
        this.tableDataSource = new MatTableDataSource<EventLog>(this.data.serverEvents);
    }

    submit() {
        const price = this.priceFormControl.value;
        this.data.invoiceItem.isPriceModified = this.data.invoiceItem.price !== price;
        this.data.invoiceItem.price = this.priceFormControl.value;
        this.data.invoiceItem.note = this.noteFormControl.value;
        this.dialogRef.close();
    }

}
