import { Injectable } from '@angular/core';
import { KeycloakAuthGuard, KeycloakService } from 'keycloak-angular';
import { Router, ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';
import { PortaluserService, UserPrivilegeTypes } from '../services/portal-user.service';
import { LoginInfoHelper } from '../helpers/login-info-helper';
import { Location } from '@angular/common';


@Injectable({
    providedIn: 'root',
  })
  export class AuthGuard extends KeycloakAuthGuard {
    constructor(
      protected readonly router: Router,
      protected readonly keycloak: KeycloakService,
      protected readonly userService: PortaluserService,
      protected readonly loginHelper: LoginInfoHelper,
      protected readonly location: Location,
    ) {
      super(router, keycloak);
    }

    public async isAccessAllowed(
        route: ActivatedRouteSnapshot,
        state: RouterStateSnapshot
    ) {

        // Force the user to log in if currently unauthenticated.
        if (!this.authenticated) {
            await this.keycloak.login({
                redirectUri: state.url,
            });
        } 
        const user = await this.userService.getMe().toPromise();
        this.loginHelper.setLoginUserToStorage(user);

        let userRoles = this.loginHelper.getUserPrivileges();
        let allowedRoles = route.data.roles;
        
        if (allowedRoles && !this.checkIfUserIsAuthorized(userRoles, allowedRoles)) {
          // role not authorised so redirect to home page
          this.router.navigate(['/error']);
          return false;
        }
        return true;

    }


    checkIfUserIsAuthorized(userRoles: Array<string>, allowedRoles: Array<string>): boolean {
      for (let i = 0 ; i < userRoles.length; i++ ) {
        if (allowedRoles.indexOf(userRoles[i]) !== -1) {
          
          return true;
        }
      }
      return false;
    }
  }
