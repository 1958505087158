import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { environment } from "src/environments/environment";
import { Observable } from "rxjs";
import { Training } from "../models/training.model";

@Injectable({
    providedIn: 'root'
})
export class TrainingService {
    private TRAINING_API = 'training';
    constructor(private http: HttpClient) { }

    public getTrainings(): Observable<Training[]> {
        return this.http.get<Training[]>(environment.dmePortalApi + this.TRAINING_API + "/list");
    }

    public getTrainingByServerName(serverName: string): Observable<Training> {
        return this.http.get<Training>(environment.dmePortalApi + this.TRAINING_API + "/get/" + serverName);
    }

    public createKWConnectTraining(courseId: String) : Observable<Training>{
        return this.http.post<Training>(environment.dmePortalApi + this.TRAINING_API + "/create-kwconnect", courseId)
    }

    public createTraining(training: Training) : Observable<Training>{
        return this.http.post<Training>(environment.dmePortalApi + this.TRAINING_API + "/create", training)
    }

    public deleteTraining(training: Training) {
        return this.http.delete(environment.dmePortalApi + this.TRAINING_API + "/delete/" + training.trainingName);
    }

    public updateTrainingEndDate(training: Training) {
        return this.http.post(environment.dmePortalApi + this.TRAINING_API + "/updateTrainingEndDate/", training);
    }

    public getTrainingsByLoginUser(): Observable<Training[]> {
        return this.http.get<Training[]>(environment.dmePortalApi + this.TRAINING_API + "/listByLoginUser");
    }

    public getKWConnectKeycloakAttributes(): Observable<Map<string, string>> {
        return this.http.get<Map<string, string>>(environment.dmePortalApi + this.TRAINING_API + "/getKWConnectKeycloak");
    }

    public stopTraining(training: Training) {
        return this.http.post(environment.dmePortalApi + this.TRAINING_API + "/stopTraining/", training);
    }

    public resumeTraining(training: Training) {
        return this.http.post(environment.dmePortalApi + this.TRAINING_API + "/resumeTraining/", training);
    }

}

