import { AppServer } from './app-server.model';

export class InvoiceItem {
    billingServer: AppServer;
    price: number;
    currency: string;
    isPriceModified: boolean;
    startPeriod: Date;
    endPeriod: Date;
    note: string;
    dayCount: number;
}
