import { AppServer } from "./app-server.model";
import { Course } from "./course.model";
import { WorkspaceUser } from "./workspace-user.model";

export class Training { 
    trainingName: string = null;
    userMail: string;
    username: string;
    endDate: Date;
    course: Course;
    server: AppServer;
    workspaceUser: WorkspaceUser;
}