import { Component, OnInit } from '@angular/core';
import { LoginInfoHelper } from 'src/app/helpers/login-info-helper';
import { Router } from '@angular/router';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-side-nav',
  templateUrl: './side-nav.component.html',
  styleUrls: ['./side-nav.component.scss']
})
export class SideNavComponent implements OnInit {
    isExpanded = false;
    constructor(
        public loginInfoHelper: LoginInfoHelper,
        private router: Router) { }

    ngOnInit() {
    }

    showPanel(): boolean {
        return this.router.url !== '/' && this.router.url.split('?')[0]  !== '/kwconnect';
    }

    isAwsWorkspaceEnabled() {
        return localStorage.getItem('isAwsWorkspaceEnabled');
    }

}
