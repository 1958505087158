import { Component, OnInit, OnChanges, SimpleChanges, Input } from '@angular/core';
import { WorkspaceService } from 'src/app/services/workspace.service';
import { WorkspaceUser } from 'src/app/models/workspace-user.model';
import { InfoDialogComponent } from '../dialogs/info-dialog/info-dialog.component';
import { PopupHelper } from 'src/app/helpers/pop-up-helper';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { FormControl, UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { MatSnackBar } from '@angular/material/snack-bar';
import { MatTableDataSource } from '@angular/material/table';
import { Training } from 'src/app/models/training.model';
import { Course } from 'src/app/models/course.model';
import { TrainingService } from 'src/app/services/training.service';
import { CourseService } from 'src/app/services/course.service';
import { ActivatedRoute, Router } from '@angular/router';
import { SubmitDialogComponent } from '../dialogs/submit-dialog/submit-dialog.component';
import { Observable } from 'rxjs';
import { finalize, map, startWith } from 'rxjs/operators';
import { WorkspaceInfoDialogComponent } from '../dialogs/workspace-info-dialog/workspace-info-dialog.component';
import { AppServer } from 'src/app/models/app-server.model';

@Component({
  selector: 'app-training',
  templateUrl: './training.component.html',
  styleUrls: ['./training.component.scss']
})
export class TrainingComponent implements OnInit {
  datasourceTrainings = new MatTableDataSource<Training>();
  awsWorkspaceForm: UntypedFormGroup;
  dataSourceCourses = new MatTableDataSource<Course>();
  isTrainingCreating = false;
  areCoursesLoaded = false;
  areTrainingsLoaded = false;
  selectedIndex = new FormControl(0);  
  loadedTrainings: Training[];
  filteredTrainingId:  Observable<string[]>
  trainingIdSearchForm = new FormControl(''); 
  filteredFormGroup: UntypedFormGroup;
  trainingIds: string[];
  date: Date | null;

  trainingsColumns = [
    {
      columnDef: 'stateIndicator',
      header: 'State',
      cell: (element: Training) => `${element}`,
    },
    {
      columnDef: 'trainingName',
      header: 'Training Name',
      cell: (element: Training) => `${element.trainingName}`,
    },
    {
      columnDef: 'userMail',
      header: 'User Mail',
      cell: (element: Training) => `${element.userMail}`,
    },
    {
      columnDef: 'courseName',
      header: 'Course Name',
      cell: (element: Training) => `${element.course}`,
    },
    {
      columnDef: 'server',
      header: 'Server Name',
      cell: (element: Training) => `${element.server}`,
    },
    {
      columnDef: 'workspaceId',
      header: 'Workspace ID',
      cell: (element: Training) => `${element.workspaceUser}`,
    },
    {
      columnDef: 'endDate',
      header: 'Training End date',
      cell: (element: Training) => `${element.endDate}`,
    },
    {
      columnDef: 'actions',
      header: '',
      cell: (element: Training) => `${element}`,
    },
  ]

  displayedColumnsTrainings = this.trainingsColumns.map(c => c.columnDef);

  constructor( 
    private courseService: CourseService, 
    private trainingService: TrainingService,
    private dialog: MatDialog,
    private popUp: MatSnackBar,
    private popUpHelper: PopupHelper,
    private route: ActivatedRoute,
    private router: Router,
  ) {
    this.refreshData();

    this.filteredFormGroup = new UntypedFormGroup({
      trainingIdSearchForm: this.trainingIdSearchForm,
    })
    this.awsWorkspaceForm = new UntypedFormGroup({
      createWorkspace: new UntypedFormControl(false), 
      course: new UntypedFormControl('', [Validators.required]),
      trainingName: new UntypedFormControl('', [Validators.required, Validators.pattern('^[0-9a-z]*$')]) ,
      endDate: new UntypedFormControl(new Date()),
    });
   }

  
  ngOnInit(): void {

  }

  isCreatingWorkspace() : boolean {
    return this.awsWorkspaceForm.get('createWorkspace').value;
  }

  createTraining() : void {
    this.isTrainingCreating = true;
    const course = this.awsWorkspaceForm.get('course').value;
    const endDate =  this.awsWorkspaceForm.get('endDate').value;
    const today = new Date();
    const tempEndDate = endDate;
    today.setHours(0, 0, 0, 0);
    tempEndDate.setHours(0, 0, 0, 0);

    if (today > tempEndDate) {
      this.isTrainingCreating = false;
      this.showErrorMessage("Please pick date later than yesterday");
      return;
    }
    const training = new Training();
    training.trainingName = this.awsWorkspaceForm.get('trainingName').value;
    if (this.isCreatingWorkspace() ) {
      training.username = training.trainingName;
    }
    training.course = course;
    training.endDate = endDate;
  
    this.popUpHelper.showPopUp('Training will be created!');
    this.trainingService.createTraining(training).subscribe(
      (data) => {
        this.isTrainingCreating = false;
        this.refreshData();
        this.popUpHelper.showPopUp("Training was created!");
      },
      (error) => {
        this.isTrainingCreating = false;
        this.refreshData();
        this.showErrorMessage("There was an error deleting Training: \n" + error.error);
      }
    ); 
  }
  
  
  refreshCourses() {    
    this.areCoursesLoaded = false;
    this.courseService.getCourses().subscribe(
      (data) => {
        this.areCoursesLoaded = true;
        this.dataSourceCourses.data = data;
      },
      (error) => {
        this.areCoursesLoaded = true;
        this.popUpHelper.showErrorPopUp("There was an error with refreshing the courses!\n",  error);
        if (error.status === 403 ) {
          this.router.navigate(['/list']);
        }
      }
    )
  }

  stopTraining(trainingToStop: Training) {
    const dialogRef = this.openSubmitDialog(
      'Confirm Training Stop',
      'Workspace is going to be deleted and Server stopped. Are you sure?');

    
      dialogRef.afterClosed().subscribe(result => {
        if (result) {
          this.popUpHelper.showLoadingPopUp("Training is being Stopped!");
          this.trainingService.stopTraining(trainingToStop).subscribe(
            (trainingDeleteOk) => {
              this.refreshData();
              this.popUpHelper.showPopUp("Training was successfully stopped!");
            },
            (trainingDeleteErr) => {
              this.refreshData();
              
              this.showErrorMessage("There was an error stopping Training\n" + trainingDeleteErr.error);
              this.popUp.dismiss();
            }
          )
        }
    });
  }

  resumeTraining(trainingToStop: Training) {
    const dialogRef = this.openSubmitDialog(
      'Confirm Training resume',
      'Workspace is going to be created and Server resumed. Are you sure?');

      dialogRef.afterClosed().subscribe(result => {
        if (result) {
          this.popUpHelper.showLoadingPopUp("Training is being Resumed!");
          this.trainingService.resumeTraining(trainingToStop).subscribe(
            (trainingDeleteOk) => {
              this.refreshData();
              this.popUpHelper.showPopUp("Training was successfully resumed!");
            },
            (trainingDeleteErr) => {
              this.refreshData();
              
              this.showErrorMessage("There was an error resuming Training\n" + trainingDeleteErr.error);
              this.popUp.dismiss();
            }
          )
        }
    });
  }

  deleteTraining(trainingToDelete: Training) {
      const dialogRef = this.openSubmitDialog(
        'Confirm Training deletion',
        'Training is going to be deleted. Are you sure?');

      
        dialogRef.afterClosed().subscribe(result => {
          if (result) {
            this.popUpHelper.showLoadingPopUp("Training is being deleted!");
            this.trainingService.deleteTraining(trainingToDelete).subscribe(
              (trainingDeleteOk) => {
                this.refreshData();
                this.popUpHelper.showPopUp("Training was successfully deleted!");
              },
              (trainingDeleteErr) => {
                this.refreshData();
                
                this.showErrorMessage("There was an error deleting Training\n" + trainingDeleteErr.error);
                this.popUp.dismiss();
              }
            )
          }
      });
  }

  refreshData() {
    this.refreshTrainings();
    this.refreshCourses();
  }


  deleteTrainingLocally(trainingToDelete: Training) {
    let tempTrainings = this.datasourceTrainings.data;
    tempTrainings = tempTrainings.filter(
      (tempTraining) => 
        (tempTraining.trainingName !== trainingToDelete.trainingName)
    );    
    this.datasourceTrainings.data = tempTrainings;
  }


  refreshTrainings() {
    this.areTrainingsLoaded = false
    let requestedTrainingId = localStorage.getItem('filteredTrainingId');
    if (requestedTrainingId && requestedTrainingId !== '') {
      this.trainingIdSearchForm.setValue(requestedTrainingId);
      localStorage.removeItem('filteredTrainingId');
    } else {
      this.trainingIdSearchForm.setValue('');
      requestedTrainingId = '';
    }
    this.trainingService.getTrainings().subscribe(
      (incommingTrainings) => {
        this.areTrainingsLoaded = true;
        this.datasourceTrainings.data = incommingTrainings;
        this.loadedTrainings = incommingTrainings;
        this.trainingIds = this.datasourceTrainings.data
              .map(training => training.trainingName)
              .filter((trainingName, index, self) => trainingName !== null && index === self.indexOf(trainingName));

        this.filteredTrainingId = this.trainingIdSearchForm.valueChanges.pipe(
            startWith(requestedTrainingId),
            map(searchValue => this.filterOptions(searchValue, this.trainingIds))
        );
        

      },
      (error) => {
        this.areTrainingsLoaded = true;
        this.popUpHelper.showErrorPopUp("There was an error with getting trainings!\n", error);
      }
    );
  }


  tagTrainingAsDateExpired(training: Training, includeNotRunning?: boolean): boolean {
    const dateForNotificationBeforeExpire = new Date();
    const formatedDate = new Date(training.endDate);
    dateForNotificationBeforeExpire.setHours(0,0,0,0);
    formatedDate.setHours(0,0,0,0);
    return training.endDate && (dateForNotificationBeforeExpire > formatedDate);
  }

  extendDate(training: Training) {
    var today = new Date();
    var newDate =  this.date;
    if (today >= newDate) {
      this.showErrorMessage("Please choose a date later than today");
      this.date = null;
      return;
    }
    training.endDate = newDate;
    var updateCall = this.trainingService.updateTrainingEndDate(training)
    .pipe(
      finalize(() => {
        this.date = null;
        this.refreshData();
      })
    );
    
    
    
    this.popUpHelper.showPopUp('Training\'s End date will be updated!');
    updateCall.subscribe(
      (data) => {
        this.popUpHelper.showPopUp('Training\'s End date was updated!');
      }, 
      (error) => {
        this.showErrorMessage(error.error.message);
      }
    )
  }
  getTrainingState(server: AppServer) {
    var classForTrainingState = "stateIndicatorCircle " + ((server.running) ? "training-running" : "training-stopped");
    return classForTrainingState;
  }

  handleChange(event: any) {
    this.selectedIndex.setValue(event);
    if (this.selectedIndex.value === 0) {
      this.refreshData();
      this.awsWorkspaceForm = new UntypedFormGroup({
        createWorkspace: new UntypedFormControl(false), 
        course: new UntypedFormControl('', [Validators.required]),
        trainingName: new UntypedFormControl('', [Validators.required, Validators.pattern('^[0-9a-zA-Z]*$')]) ,
        endDate: new UntypedFormControl(new Date()),
      });
    }
  }

  showInfo(workspace: WorkspaceUser) : void {
    this.dialog.open(WorkspaceInfoDialogComponent, {
      width: '450px',
      data: workspace,
    });
  }

  public errorHandling = (control: string, error: string) => {
    return this.awsWorkspaceForm.controls[control].hasError(error);
  }

  switchToWorkspace(workspace: any) {
    localStorage.setItem('filteredWorkspaceId', workspace.workspaceId);
    this.selectedIndex.setValue(1);
  }

  rerouteToServer(training: any) {
    localStorage.setItem("filterServerName", training.server.serverName);
    this.router.navigate(['/list']);
  }
  
  private filterOptions(value: any, list: string[]): string[] {
    const filterValue = value.toLowerCase();
    const filteredTrainings = list.filter(item => item.toLowerCase().includes(filterValue)); 
    this.datasourceTrainings.data = this.loadedTrainings.filter((training) => training.trainingName.includes(value));    
    return filteredTrainings;
  }

  
  private openSubmitDialog(title: string, message: string): MatDialogRef<SubmitDialogComponent, any> {
    return this.dialog.open(SubmitDialogComponent, {
        width: '450px',
        data: { title, message }
    });
  }
  
  showErrorMessage(message: string) {
    this.dialog.open(InfoDialogComponent, {
      width: '450px',
      data: { "title" : "Error", "message": message},
    });
  }
}

