import { Injectable } from '@angular/core';
import { KeycloakService } from 'keycloak-angular';
import { ConfigInitService } from './config-init.service';

@Injectable({
    providedIn: 'root'
})
export class AuthService {

    constructor(
        private keycloakService: KeycloakService,
    ) {

    }



    getLoggedUser() {
        return this.keycloakService.getUsername();
    }

    logout() {
        this.keycloakService.logout();
    }


}
