import { Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import { environment } from 'src/environments/environment';
import { Observable } from 'rxjs';
import { PortalUser } from '../models/portal-user.model';
import { VolumeInfo } from '../models/volume-info.model';
import { AppServer } from '../models/app-server.model';

@Injectable({
    providedIn: 'root'
})

export class VolumeService {
    private VOLUME_API = 'volume';
    constructor(private http: HttpClient) { }

    public getVolumes(): Observable<VolumeInfo[]> {
        return this.http.get<VolumeInfo[]>(environment.dmePortalApi + this.VOLUME_API + '/list');
    }

    public getVolume(appServer: AppServer): Observable<VolumeInfo> {
        let params = new HttpParams();
        params = params.set('appSeverName', appServer.serverName);

        return this.http.get<VolumeInfo>(environment.dmePortalApi + this.VOLUME_API, {params});
    }

    public getVolumeSizeLimit(): Observable<number> {
        return this.http.get<number>(environment.dmePortalApi + this.VOLUME_API + '/sizeLimit');
    }

    public getVolumeSizeTotal(): Observable<number> {
        return this.http.get<number>(environment.dmePortalApi + this.VOLUME_API + '/sizeTotal');
    }

    public cleanVolume(appServer: AppServer): Observable<number> {
        let params = new HttpParams();
        params = params.set('appSeverName', appServer.serverName);

        return this.http.get<number>(environment.dmePortalApi + this.VOLUME_API + '/cleanVolume',  {params});
    }
}
